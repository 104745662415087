
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Table, Input, Button,InputGroup ,InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs ,H5} from '../../../../AbstractElements';
// import Commen from "../../../Common/Component/Commen
import HeaderCard from '../../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import { IoMdClose } from "react-icons/io";
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import classNames from "classnames";
import { Category, MarginLeft, MarginRight } from '../../../../Constant';
import { MdViewHeadline } from "react-icons/md";
import { Events } from 'leaflet';

function Taxelement() {
  const previousInputValue = useRef("");
  const previousInputValue2 = useRef();
  const [name, setName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [submitbtn, setSubmintbtn] =useState('Save');
  const [categoryName1, setCategoryName1] = useState('');
  const [loadonInventory, setLoadonInventory] = useState(null);
  const [description, setDescription] = useState('');
  const [under, setUnder] = useState(null);
  const [assign, setAssign] = useState(null);
  const [gstcategory, setGstcategory] = useState(null);
  const [gsttype, setGsttype] = useState(null);
  const [salewarehouse, setSalewarehouse] = useState(null);
  const [categories, setCategories] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [impactOptions, setImpactOptions] = useState([]);
  const [abatement, setAbatement] = useState([]);
  const [typeOption, setTypeOptions] = useState([]);
  const [nameError1 , setNameError1] = useState(null);
  const [descriptionError1, setDescriptionError1] = useState(null);
  const [nameError2 , setNameError2] = useState(null);
  const [nameError3 , setNameError3] = useState(null);
  const [descriptionError2, setDescriptionError2] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownCatOpen, setDropdownCatOpen] = useState(false);
  const [rows, setRows] = useState([{ from_date: '', to_date: '', percent: '' }]);
  const [dropdownOwnOpen, setDropdownOwnOpen] = useState(false);
  const [dropdownPurOpen, setDropdownPurOpen] = useState(false);
  const [hsnOptions, setHsnOptions] = useState([]);
  const [sacOptions, setSacOptions] = useState([]);
  const [dropdownSalOpen, setDropdownSalOpen] = useState(false);
  const [shouldFocusName, setShouldFocusName] = useState(false);
  const [fixedOpen, setFixedOpen] = useState(false);
  const [variableOpen, setVariableOpen] = useState(false);
  const [filteredPaymentBase, setFilteredPaymentBase] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [abModalOpen, setAbModalOpen] = useState(false);
  const [fixedRow, setFixedRow] = useState([{ from_date: '', to_date: '', percent: '' }]);
  const [variableRow, setVariableRow] = useState([{ from_date: '', to_date: '', hsn: '', hsn_percent: '', sac: '', sac_percent: '' }]);
  const [rateType, setRateType] = useState('');
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);
  const [viewbutton , setViewbutton] = useState(false);
  const [naturebutton , setNaturebutton] = useState(false);

  useEffect(() => {
    previousInputValue.current = rateType;
    previousInputValue2.current = abatement;
    if(abatement.value === 'no'){
      setViewbutton(false);
    }
  }, [rateType,abatement],

  

);

console.log(previousInputValue2.current,"previousInputValue2.current")
  

  const abatementopt = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ];
  const rowsPerPage = 10;
  const handleAddRow = () => {
    setRows([...rows, { from_date: '', to_date: '', percent: '' }]);
  };
  const handleFixedAddRow = () => {
    setFixedRow([...fixedRow, { from_date: '', to_date: '', percent: '' }]);
  };
  const handleVariableAddRow = () => {
    setVariableRow([...variableRow, { from_date: '', to_date: '', hsn: '', hsn_percent: '', sac: '', sac_percent: '' }]);
  };
  const customStylesTable = {
    header: {
      style: {
        minHeight: '56px', // Adjust header height
        backgroundColor: '#f0f0f0', // Light grey background for the header
        color: '#333', // Text color
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0', // Grey color for the table header row
      },
    },
    headCells: {
      style: {
        fontSize: '14px', // Font size for header cells
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000', // Header text color
      },
    },
    rows: {
      style: {
        minHeight: '48px', // Minimum height of rows
        borderBottom: '1px solid #ddd', // Bottom border for rows
      },
    },
  };
  const popupopStyles ={
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
      boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
      '&:hover': {
          borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
      },
  }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => null,
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '34px',
    }),
    // Add zIndex to ensure dropdown is visible above other elements
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,  // High z-index value
      position: 'absolute',
    }),
  }
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        width:'100%',
        borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
        '&:hover': {
            borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
        },
    }),

};
  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
  console.log(filteredPaymentBase)
  const highlightFirstOccurrence = (text, searchTerm) => {
    const index = text.indexOf(searchTerm); // Find the first occurrence
    if (index !== -1) {
      return [{ start: index, end: index + searchTerm.length }];
    }
    return [];
  };
  
  const VendorNameCell = ({ taxcluster_name, searchTerm }) => (
    <Highlighter
      highlightClassName="highlighted-text"
      searchWords={[]} // Not used since we provide custom chunks
      autoEscape={true}
      textToHighlight={taxcluster_name}
      findChunks={() => highlightFirstOccurrence(taxcluster_name.toLowerCase(), searchTerm)}
    />
  );

  const columns = [
    {
      name: (<div className='dataTableDiv'>SN</div>),
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      width:'70px',
      cell:(row, index) =>(
        <div className='dataTableDiv' style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name: (<div className='dataTableDiv'>Tax Element</div>),
      selector: row => row.tax_element,
      // width: '300px',
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
        <VendorNameCell taxcluster_name={row.tax_element} searchTerm={searchTerm.toLowerCase()} />
        </div>
      ),
    },
    {
      name:'',
      width:'5px',
    },
    {
      name: (
        <div className='dataTableDiv'>Description</div>
      ),
      selector: row => row.description,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          {row.description}
        </div>
      ),
    },
    {
      name:(<div className='dataTableDiv'>GST Category</div>),
      selector: row => row.gst_category_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          {row.gst_category_name}
        </div>
      ),
    },
    {
      name: (<div className='dataTableDiv'>GST Type</div>),
      selector: row => row.gst_type_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
         {row.gst_type_name}
        </div>
      ),
    },
    {
      name: (<div className='dataTableDiv'>Ledger name</div>),
      selector: row => row.ledger_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          {row.ledger_name}
        </div>
      ),
      
    },
    {
      name: (<div className='dataTableDiv'>Action</div>),
      center: true,
      width:'100px',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];


  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCategories(categories || []); // Ensure categories is an array or empty array
    } else {
      const safeCategories = Array.isArray(categories) ? categories : [];
      const filteredData = safeCategories.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filteredData);
    }
    fetchCategories(currentPage);
    // fetchSelectOptions();
    fetchimpactOptions();
    fetchTypeOptions();
    fetchHsnOptions();
    fetchSacOptions();
    fetchLedgerOptions();
  }, [searchTerm, currentPage]); // Add categories as a dependency

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      let response;
      if (searchTerm) {
          response = await axiosInstance.get(`taxation/taxelement/search/?name=${searchTerm}`);
      } else {
          response = await axiosInstance.get(`taxation/taxelement/get/`);
      }
      setCategories(response.data);
      setFilteredCategories(response.data); 
    } catch (error) {
      if (error.response && error.response.status === 404 && page > 1) {
        handlePageChange(page - 1); 
      } else {
        console.error("Error fetching categories:", error);
      }
    }finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCategories(page);
  };
  // const fetchSelectOptions = async (editId = null) => {
  //   try {
  //     const response = await axiosInstance.get('taxation/taxelement/get/');
  //     console.log(editId,'snckdsvv');
  //     const filteredOptions = response.data
  //     .filter(category => category.id !== editId) // Exclude the category being edited
  //     .map(category => ({
  //       value: category.id,
  //       label: category.name
  //     }));
  //     setSelectOptions([{ value: '1', label: '[] Primary' }, ...filteredOptions]);
  //   } catch (error) {
  //     console.error('Error fetching select options:', error);
  //   }
  // };

  // const fetchCategoryOptions = async (editId = null) => {
  //   try {
  //     const response = await axiosInstance.get('generalledger/coaledger/get/');
  //     console.log(editId,'snckdsvv');
  //     const filteredOptions = response.data
  //     .filter(category => category.id !== editId) // Exclude the category being edited
  //     .map(category => ({
  //       value: category.id,
  //       label: category.name
  //     }));
  //     setCategoryOptions([ ...filteredOptions]);
  //   } catch (error) {
  //     console.error('Error fetching select options:', error);
  //   }
  // }; 
  const fetchTypeOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/gsttaxcategory/get/');
      
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setTypeOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  const fetchHsnOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/hsn/get/');
    
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setHsnOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  const fetchSacOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/sac/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setSacOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  const fetchLedgerOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('generalledger/coaledger/get/');
      console.log(editId,'snckdsvv');
      const opt = response.data.map(category => ({
        value: category.id,
        label: `${category.account}(${category.name})`
      }));
      console.log(opt,'options');
      const filteredOptions = response.data
      .filter(category => category.id !== editId)
      .map(category => ({
        value: category.id,
        label: `${category.account} -- ${category.name}`
      }));
      setSelectOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };
  const fetchimpactOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/gsttaxtype/get/');
      console.log(editId,'snckdsvv');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setImpactOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//     setCurrentPage(1);
// };

const isAbatmentDataFilled = () => {
  // Check if every item in the array has all fields filled
  return rows.every(
    (row) => row.from_date && row.to_date && row.percent
  );
}
// const handleSubmit = async (e) => {
   
//   // let hi = fixedRow.map(item => item.from_date)
//   // let hasEmptyValue = hi.some(data => !data)
//   // if(hasEmptyValue) {
//   //   console.log("empty")
//   //   setCategoryName('Kindly enter the data for the Tax nature')
//   // }
//   // }
 
//   // if (isAbatmentDataFilled(data)) {
//   //   console.log("All fields are filled.");
//   // } else {
//   //   console.log("Some fields are missing.");

//   // }
//   let checkvaliddata = false;

//   // First validation block
//   if (
//     (fixedRow[0].from_date === "" || fixedRow[0].to_date === "" || fixedRow[0].percent === "") &&
//     (variableRow[0].from_date === '' || variableRow[0].to_date === '' || variableRow[0].hsn === '')
//   ) {
//     alert("Either Fixed or Variable Data should be filled compulsorily");
//     e.preventDefault();
//     checkvaliddata = true; // Validation failed
//   }
  
//   // Second validation block
//   if (
//     rows.length <= 0 && abatement.value === 'yes' ||
//     rows.some(row => row.from_date === '' || row.to_date === '' || row.percent === 0)
//   ) {
//     alert('Kindly Enter the Abatement value data');
//     setAbModalOpen(true);
//     setRows([{ from_date: '', to_date: '', percent: '' }]);
//     e.preventDefault();
//     checkvaliddata = true; // Validation failed
//   }
  
//   // Stop further execution if validation failed
//   if (checkvaliddata) {
//     return; // Exit the function if there was a validation error
//   }
  
 



//   console.log(abatement.value,"abatementabatementabatement")
//   e.preventDefault();
//   const payload = {
//     tax_element : name,
//     description,
//     load_on_inventory : isChecked === false ? 0 : loadonInventory,
//     abatement : rows,
//     fixedNature :fixedRow ,
//     variableNature : variableRow,
//     gst_type : gsttype ? gsttype.value : null,
//     gst_category : gstcategory ? gstcategory.value : null,
//     ledger : under ? under.value : null,
//   };
//   setNameError1(null);
//   setDescriptionError1(null);

//   try {
//     if (isEditing) {
//       await axiosInstance.put(`taxation/taxelement/${editId}/`, payload);
//       setIsEditing(false);
//     } else {
//       await axiosInstance.post('taxation/taxelement/create/', payload);
//       setErrorMessage(null);
//     }
//     fetchCategories(currentPage);
//     fetchimpactOptions();
//     fetchTypeOptions();
//     fetchHsnOptions();
//     fetchSacOptions();
//     fetchLedgerOptions();
//     // fetchCategoryOptions();
//     resetForm();
//     // Swal.fire({
//     //   title: 'Success!',
//     //   text: 'Tax Element has been saved successfully.',
//     //   icon: 'success',
//     //   confirmButtonText: 'OK'
//     // });
//   } catch (error) {
//     console.error('Error:', error);
//     if (error.response && error.response.data) {
//       const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
//       const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
//       const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
//       console.log(nameError, 'Name Error');
//       console.log(descriptionError, 'Description Error');
//       if (nameError && descriptionError) {
//         setNameError1(nameError);
//         setDescriptionError1(descriptionError);
//       } else if (nameError) {
//         setNameError1(nameError)
//       } else if (descriptionError) {
//         setDescriptionError1(descriptionError);
//       } else if (existsError) {
//         setErrorMessage(existsError);

//       }
//     } else {
//       setErrorMessage('An error occurred while submitting the form.');
//     }
//   }
// };

const handleSubmit = async (e) => {
  e.preventDefault();
  // let hi = fixedRow.map(item => item.from_date)
  // let hasEmptyValue = hi.some(data => !data)
  // if(hasEmptyValue) {
  //   console.log("empty")
  //   setCategoryName('Kindly enter the data for the Tax nature')
  // }
  // }
 
  // if (isAbatmentDataFilled(data)) {
  //   console.log("All fields are filled.");
  // } else {
  //   console.log("Some fields are missing.");
  // }

  if(rateType==='fixed'){
    console.log("Fixed filllll")
    let hasError = false; // Track overall validation status
      let errorMessages = []; // Collect error messages
      
      fixedRow.forEach((item, index) => {
        if (item.from_date === "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: From Date should not be null .`);
          hasError = true;
        }
        if (item.to_date === "") {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: To Date should not be null.`);
          hasError = true;
        }
        if (item.percent === "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: Percent should not be null .`);
          hasError = true;
        }
       
      });
      
      if (hasError) {
        alert(`Fixed Nature:\n${errorMessages.join("\n")}`); // Show all error messages in one alert
        return;
      } else {
        setNaturebutton(true)
      }
      
  }else if(rateType==='variable'){
    let hasError = false; // Track overall validation status
      let errorMessages = []; // Collect error messages
      
      variableRow.forEach((item, index) => {
        if (item.from_date === "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: From Date should not be null .`);
          hasError = true;
        }
        if (item.to_date === "") {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: To Date should not be null.`);
          hasError = true;
        }
        if (item.hsn !== "" && item.sac !== "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: HSN or SAC Any one data should be Entered  `);
          hasError = true;
        }
        if (item.hsn && !item.hsn_percent) {
          errorMessages.push(`Row ${index}: HSN Percent should not be null when HSN is entered.`);
          hasError = true;
        }
    
        // Validate SAC Percent if SAC is filled
        if (item.sac && !item.sac_percent) {
          errorMessages.push(`Row ${index}: SAC Percent should not be null when SAC is entered.`);
          hasError = true;
        }
      });
      
      if (hasError) {
        alert(`Variable Nature:\n${errorMessages.join("\n")}`); // Show all error messages in one alert
        return;
      } else {
        setNaturebutton(true)
      }
  }
  if (
    abatement.value === "yes" &&
    (rows.length === 0 || rows.some((row) => !row.from_date || !row.to_date || row.percent === 0))
  ) {
    alert("Kindly Enter the Abatement value data.");
    setAbModalOpen(true);
    setRows([{ from_date: "", to_date: "", percent: "" }]);
    return;
  }else if(rateType===''){
    alert("Please select the tax nature");
    return;
  }

  console.log(abatement.value,"abatementabatementabatement")
  
  const payload = {
    tax_element : name,
    description,
    load_on_inventory : isChecked === false ? 0 : loadonInventory,
    abatement : rows,
    fixedNature : fixedRow,
    variableNature : variableRow,
    gst_type : gsttype ? gsttype.value : null,
    gst_category : gstcategory ? gstcategory.value : null,
    ledger : under ? under.value : null,
  };
  setNameError1(null);
  setDescriptionError1(null);

  try {
    if (isEditing) {
      await axiosInstance.put(`taxation/taxelement/${editId}/`, payload);
      setIsEditing(false);
    } else {
      await axiosInstance.post('taxation/taxelement/create/', payload);
      setErrorMessage(null);
    }
    fetchCategories(currentPage);
    fetchimpactOptions();
    fetchTypeOptions();
    fetchHsnOptions();
    fetchSacOptions();
    fetchLedgerOptions();
    resetForm();
  } catch (error) {
    console.error('Error:', error);
    if (error.response && error.response.data) {
      const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
      const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
      const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
      console.log(nameError, 'Name Error');
      console.log(descriptionError, 'Description Error');
      if (nameError && descriptionError) {
        setNameError1(nameError);
        setDescriptionError1(descriptionError);
      } else if (nameError) {
        setNameError1(nameError)
      } else if (descriptionError) {
        setDescriptionError1(descriptionError);
      } else if (existsError) {
        setErrorMessage(existsError);

      }
    } else {
      setErrorMessage('An error occurred while submitting the form.');
    }
  }

  
};

  const getAbatment =(abatement)=>{
    if(Array.isArray(abatement) && abatement.length === 0){ 
      setRows([{ from_date: '', to_date: '', percent: '' }]);
      setAbatement([{ value: 'no', label: 'No' }]);
    }
    else{
      setAbatement([{ value: 'yes', label: 'Yes' }]);
      setViewbutton(true);
      

    }
  }

  const getfixed = (fixedNature)=>{
    if(Array.isArray(fixedNature) && fixedNature.length >= 1){
      setRateType('fixed')
      setVariableRow([{ from_date: '', to_date: '', hsn: '', hsn_percent: '', sac: '', sac_percent: '' }]);
    }
  }

  const getnature = (variableNature)=>{
    if(Array.isArray(variableNature) && variableNature.length >= 1){
      setRateType('variable')
      setFixedRow([{ from_date: '', to_date: '', percent: '' }]);
    }
  }
  // function getdatayn(categories){


  const handleChanges = (e) => {
   let  value = e.target.value;
    setLoadonInventory(value); 

  }

  const handleUpdateClick = (category) => {
    setRows([])
    setRateType(' ');
    setEditId(category.id);
    setNameError2(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    // setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setErrorMessage('');
    setNameError1(null);
    setDescriptionError1(null);
    setName(category.tax_element);
    setDescription(category.description);
    setSubmintbtn('Update');
    console.log(category.id,'nbhgvcsfdnhvbjmb');
    // setUnder({ value: category.under, label: category.under_name });
    setGstcategory({value: category.gst_category, label: category.gst_category_name});
    setUnder({value: category.ledger, label: category.ledger_name});
    setGsttype({value: category.gst_type, label: category.gst_type_name});
    // setLoadonInventory(() => { setIsChecked(true); return category.load_on_inventory; });
    setLoadonInventory(() => {
      if (category.load_on_inventory > 0) {
        setIsChecked(true);
      }
      return category.load_on_inventory || 0;
    });
    
    setRows(category.abatement);
    setFixedRow(category.fixedNature);
    setVariableRow(category.variableNature);

    getAbatment(category.abatement);
    getfixed(category.fixedNature);
    getnature(category.variableNature);
    // setAdabpt(category.abatement);
    // setFnatures(category.fixedNature);
    // setVnatures(category.variableNature);
    setNaturebutton(true);
    // setSalewarehouse({value: category.salewarehouse, label: category.salewarehouse_name});
    setIsEditing(true);
    // fetchSelectOptions(category.id);
    fetchimpactOptions(category.id);
    fetchTypeOptions(category.id);
    fetchHsnOptions(category.id);
    fetchSacOptions(category.id);
    // fetchCategoryOptions(category.id);
    setErrorMessage('');
  };

  const resetForm = () => {
    setNameError2(null);
    setNameError3(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setUnder(null);
    setGstcategory(null);
    setAssign(null);
    setAbatement([]);
    setIsChecked(false);
    setGsttype(null);
    setSalewarehouse(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setEditId(null);
    setRateType(''); 
    setViewbutton(false);
    setVariableRow([]);
    setLoadonInventory(null);
    setErrorMessage('');
    setRows([{ from_date: '', to_date: '', percent: '' }]);
    setVariableRow([{ from_date: '', to_date: '', hsn: '', hsn_percent: '', sac: '', sac_percent: '' }]);
    setFixedRow([{ from_date: '', to_date: '', percent: '' }]);
    setCategoryName('');
    setCategoryName1('')
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedIds(selectedRows.selectedRows.map(row => row.id));
  };
  const handleDelete = async () => {
    try {
        await axiosInstance.delete('taxation/taxelement/delete/', {
          data: { ids: selectedIds },
        });
        fetchCategories(currentPage);
        setSelectedIds([]);
        // fetchSelectOptions();
        fetchimpactOptions();
        fetchTypeOptions();
        fetchHsnOptions();
        fetchSacOptions();
        fetchLedgerOptions();
        // fetchCategoryOptions();
        resetForm();
      // }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
      Swal.fire('Error!',errorMessage , 'error');
      
    }
  };

  const handleDeleteCategory = async (id) => {
      try {
        await axiosInstance.delete(`taxation/taxelement/${id}/`);
        fetchCategories(currentPage);
      } catch (error) {
       
        const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
        Swal.fire('Error!',errorMessage , 'error');
  
      }

      setSelectedIds([]);
      // fetchSelectOptions();
      fetchimpactOptions();
      fetchTypeOptions();
      fetchLedgerOptions();
      fetchHsnOptions();
      fetchSacOptions();
      // fetchCategoryOptions();
      resetForm();
    // }
  };
  const getHighlightedText = (text, highlight) => {
    if (!text || typeof text !== 'string') {
        return <span>{text}</span>;
    }
    if (!highlight || typeof highlight !== 'string') {
        return <span>{text}</span>; 
    }
    const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
        <span>
            {parts.map((part, index) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <span key={index} style={{ fontWeight: 'bold' }}>{part}</span>
                ) : (
                    part
                )
            )}
        </span>
    );
  };
  const validateName = (value) => {
    console.log(value,'kjhvckjsdnv');
    
   if (value.match('  ')) {
      setNameError1('Continuous spaces are not allowed ');
      return false;
    }  else if(value.length === 30){
      setNameError2(null);
    }else {
      setNameError1(null);
      return true;
    }
  };

  const handleNameChange = (e) => {
      const value = e.target.value;
      setName(value);
      validateName(value); 
  };
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
};

 
     const handleEnter = (event) => {

   
    
    if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
      event.target.focus();  
    } 
  
    else if (event.keyCode === 13 && event.target.form) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        event.target.value = event.target.value.trim();
      }
      if (dropdownOpen) {
        return; 
      }else if(dropdownCatOpen){
        return;
      }else if(dropdownOwnOpen){
        return;
      }else if(dropdownPurOpen){
        return;
      }else if(dropdownSalOpen){
        return;
      } else {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form.elements, event.target);
        const nextElement = form.elements[index + 1];
        if (nextElement) {
          nextElement.focus();
        }
        event.preventDefault();
      }
    }

    

    
    const { value } = event.target;
    if (
      (event.key === ' ' && value.length === 0) || 
      (event.key === ' ' && value.endsWith(' ')) || 
      (event.key === ' ' && value.includes('  '))
    ) {
      event.preventDefault();
      return;
    }
    

   
  };
  
  const handleArrowNavigation = (event, buttonType) => {
    if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  };
    const handleBlur = (e) => {
      setNameError2(null);
      setDescriptionError2(null);
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
        
        if (nameError1) {
            e.target.focus();
        }else if (descriptionError1) {
          e.target.focus(); 
        }
      }
    };

  
    const handleDescriptionFocus = () => {
      // Perform name validation when switching to the description field
      if(isEditing){
        const otherCategories = categories.filter(category => category.id !== editId);
        console.log(otherCategories,'is editing');
        if (Array.isArray(otherCategories) && otherCategories.some((item) => item.tax_element.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())) {
          setShouldFocusName(true);
          setNameError3('Tax Element with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
        }, 0);
        }
        else {
          setNameError3(null);
        }
        
      }else{
        if(Array.isArray(categories) && categories.some((item) => item.tax_element.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())){
          setShouldFocusName(true);
          setNameError3('Tax Element with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
          }, 0);
          return false;
        }else {
          setNameError3(null);
        }
  
      }
    };
    const handleInCheckboxChange = (e) => {
      setIsChecked(e.target.checked); // Update state based on checkbox value
      setLoadonInventory("0")
    };
    // const handleCancelAbatement = () => {
    //   const noOption = abatementopt.find(option => option.value === 'no');
    //   // setAbatement(noOption);
    //   setAbModalOpen(false);  // Close modal
    //   setAbatement([{ value: 'no', label: 'No' }]);
    //   setRows([{ from_date: '', to_date: '', percent: '' }]);

    // };
    // const handleCancelFixed = () => {
    //   setFixedOpen(false);  // Close modal
    //   setRateType('');
    //   setFixedRow([{ from_date: '', to_date: '', percent: '' }]);
    // };
    // const handleCancelVaraible = () => {
    //   setVariableOpen(false);
    //   setRateType('');
    //   setVariableRow([{ from_date: '', to_date: '', hsn: '', hsn_percent: '', sac: '', sac_percent: '' }]);  
    // }

    
    
    // const[abtyes,setAbtYes] = useState(false);
    // const[abtno,setAbtNo] = useState(false);

   
    const handleAbatement = (option) => {
      console.log(option);
      setAbatement(option);
    
      if (option.value === "yes") {
        setAbModalOpen(true); // Open modal if "yes" is selected
        setViewbutton(true); // Ensure the view button is visible
      } else if (option.value === "no") {
        // Check if Abatement data exists
        const hasDataInAbatement = 
          rows[0].from_date !== "" || 
          rows[0].to_date !== "" || 
          rows[0].percent !== "";
    
        if (hasDataInAbatement) {
          alert("Delete the data in Abatement and select the NO option");
          setAbModalOpen(true); // Open the modal for confirmation
          setViewbutton(true); // Keep the view button visible
        } else {
          // No data exists, safely close modal and hide the button
          setAbModalOpen(false);
          setViewbutton(false);
        }
      }
    };
    
    const handleAbatementsubmit = () => {

      let hasError = false; // Track overall validation status
      let errorMessages = []; // Collect error messages
      
      rows.forEach((item, index) => {
        if (item.from_date === "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: From Date should not be null .`);
          hasError = true;
        }
        if (item.to_date === "") {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: To Date should not be null.`);
          hasError = true;
        }
        if (item.percent === "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: Percent should not be null .`);
          hasError = true;
        }
       
      });
      
      if (hasError) {
        alert(`Abatment:\n${errorMessages.join("\n")}`); // Show all error messages in one alert
      } else {
        setAbModalOpen(false); // Proceed only if no errors
      }

    }
    const handleFixed = ()=>{
      let hasError = false; // Track overall validation status
      let errorMessages = []; // Collect error messages
      
      fixedRow.forEach((item, index) => {
        if (item.from_date === "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: From Date should not be null .`);
          hasError = true;
        }
        if (item.to_date === "") {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: To Date should not be null.`);
          hasError = true;
        }
        if (item.percent === "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: Percent should not be null .`);
          hasError = true;
        }
       
      });
      
      if (hasError) {
        alert(errorMessages.join("\n")); // Show all error messages in one alert
      } else {
        setNaturebutton(true)
        setFixedOpen(false); // Proceed only if no errors
      }
      
      
      
    };
    const handleVariable =() =>{
      let hasError = false; // Track overall validation status
      let errorMessages = []; // Collect error messages
      
      variableRow.forEach((item, index) => {
        if (item.from_date === "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: From Date should not be null .`);
          hasError = true;
        }
        if (item.to_date === "") {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: To Date should not be null.`);
          hasError = true;
        }
        if (item.hsn !== "" && item.sac !== "" ) {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: HSN or SAC Any one data should be Entered  `);
          hasError = true;
        }
        //   if (item.sac === "") {
        //   errorMessages.push(`Row ${JSON.parse(index + 1)}: SAC should not be null `);
        //   hasError = true;
        // }
        // if (item.hsn_percent === "") {
        //   errorMessages.push(`Row ${JSON.parse(index + 1)}: HSN Percent should not be null `);
        //   hasError = true;
        // }
      
        // if (item.sac_percent === "") {
        //   errorMessages.push(`Row ${JSON.parse(index + 1)}: SAC Percent should not be null `);
        //   hasError = true;
        // }
        if (item.hsn !== '' && item.hsn_percent === "") {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: HSN Percent should not be null `);
          hasError = true;
            
        }
    
        if (item.sac !== '' && item.sac_percent === "") {
            errorMessages.push(`Row ${JSON.parse(index + 1)}: SAC Percent should not be null `);
          hasError = true;
        }
       
      });
      
      if (hasError) {
        alert(errorMessages.join("\n")); // Show all error messages in one alert
      } else {
        setNaturebutton(true)
        setVariableOpen(false); // Proceed only if no errors
      }
     

    };
    const handleRowChange = (index, field, value) => {
      const newRows = [...rows];
      newRows[index][field] = value;
      setRows(newRows);
    };
   
    const handleDeleteRow = (index) => {
   
      const newRows = [...rows];
      newRows.splice(index, 1); // Remove the row at the specified index
      setRows(newRows);
      if(newRows.length === 0){
        setRows([
          { from_date: '', to_date: '', percent: '' }
        ])

      }
    };
    const handleFixedDeleteRow = (index) => {
      const newRows = [...fixedRow];
      newRows.splice(index, 1); // Remove the row at the specified index
      setFixedRow(newRows);
      if(newRows.length === 0){
        setFixedRow([
          { from_date: '', to_date: '', percent: '' }
        ])
        

      }
    };
    const handlVariableDeleteRow = (index) => {
      const newRows = [...variableRow];
      newRows.splice(index, 1); // Remove the row at the specified index
      setVariableRow(newRows);
      if(newRows.length === 0){
        setVariableRow([
          { from_date: '', to_date: '', hsn: '', hsn_percent: '', sac: '', sac_percent: '' }
        ])
        

      }
    };
    const handleInputChange = (index, event) => {
      const { name, value } = event.target;
      const newRows = [...rows];
      if (name === "percent") {
        newRows[index][name] = parseFloat(value, 10); // convert to integer
      } else {
        newRows[index][name] = value; // for other fields, set as string
      }
      
      setRows(newRows);
      console.log(rows,'adaptmet naga')
  };
  const handleFixedChange = (index, event) => {
    console.log(event,"event")
    const { name, value } = event.target;
    const newRows = [...fixedRow];
    if (name === "percent") {
      newRows[index][name] = parseFloat(value, 10); // convert to integer
    } else {
      newRows[index][name] = value; // for other fields, set as string
    }
    setFixedRow(newRows);
    
    console.log(fixedRow,'Added')
};
const handleVariableChange = (index, event) => {

  const { name, value } = event.target;
  console.log(event.target,"event.targetevent.target")

  const newRows = [...variableRow];
  if (name === "hsn_percent" || name === "sac_percent") {
    newRows[index][name] = parseFloat(value, 10); // convert to integer
  } else {
    newRows[index][name] = value; // for other fields, set as string

  }
  setVariableRow(newRows);
  console.log(newRows,'AddedAddedAdded')

  console.log(variableRow,newRows,'Added')
};
 



  const handleRateTypeChange = (type) => {
  
    setRateType(type);
    console.log(type,"rateType")
    if (type === 'variable') {
      setVariableOpen(true);  // Open popup if "Variable" is selected
      setVariableRow([...variableRow]);
    } else {
      setFixedOpen(true); // Close popup if "Fixed" is selected
      setFixedRow([...fixedRow]);
      
    }
    
    if(type === 'variable' && previousInputValue.current === 'fixed'){
      if(type === 'variable' && (fixedRow[0].from_date !== ""|| fixedRow[0].to_date !== "" || fixedRow[0].percent !== "" ) ){
        console.log(previousInputValue.current,rateType,fixedRow[0].from_date,"click me")
        if(previousInputValue.current === 'fixed'){
          console.log("click me1")
          alert("Delete the data in Fixed nature and Enter the data in Variable nature ");
          setVariableOpen(false);
          setFixedOpen(true);
          setRateType('fixed');
          
          // setVariableOpen(false);
        }
      
      }

    }else{
      if(type === 'fixed' && (variableRow[0].from_date !== "" || variableRow[0].to_date !== "" || variableRow[0].hsn !== "" || variableRow[0].hsn_percent !== "" || variableRow[0].sac !== "" || variableRow[0].sac_percent !== "" ) ){
        console.log(previousInputValue.current,rateType,fixedRow[0].from_date,"click me")
        if(previousInputValue.current === 'variable'){
          console.log("click me1")
          alert("Delete the data in variable nature and Enter the data in fixed nature ");
          setFixedOpen(false);
          setVariableOpen(true);
          setRateType('variable')
          // setVariableOpen(false);
        }
      
      }

    }
  
  };
  const handleSelectHsnChange = (index, selectedOption, name) => {
    console.log(index, selectedOption, name)
    const newRows = [...variableRow];
    newRows[index][name] = selectedOption.value;
    setVariableRow(newRows);
  };
  const handleSelectSacChange = (index, selectedOption, name) => {
    console.log(index,selectedOption,name)
    const newRows = [...variableRow];
    newRows[index][name] = selectedOption.value;
    
    setVariableRow(newRows);
  };

const handleclick =()=>{
  console.log(rows,"nagasaravanan ")
  
  setAbModalOpen(true)
  if (Array.isArray(rows) && rows.length >= 1 ){
    const newRows = rows.map(item => ({
      from_date: item.from_date,
      to_date: item.to_date,
      percent: item.percent
    }));
    setRows(newRows);
  }
  if(Array.isArray(rows) && rows.length >= 1){
    const newRows = rows.map(item => ({
      from_date: item.from_date,
      to_date: item.to_date,
      percent: item.percent
    }));
    setRows(newRows);
  }
  
}


// const handleFixedAddRow = () => {
//   setFixedRow([...fixedRow, { from_date: '', to_date: '', percent: '' }]);
// };
// const handleVariableAddRow = () => {
//   setVariableRow([...variableRow, { from_date: '', to_date: '', hsn: '', hsn_percent: '', sac: '', sac_percent: '' }]);
// };


const handleclick1 =()=>{

  
  if(rateType ===  'fixed'){
    if (Array.isArray(fixedRow) && fixedRow.length >= 1){ 
      setFixedOpen(true)
      setVariableOpen(false)
      const newRows = fixedRow.map(item => ({
        from_date: item.from_date,
        to_date: item.to_date,
        percent: item.percent
      }));
      setFixedRow(newRows);
      }
    
      // if (Array.isArray(fixedRow) && fixedRow.length >= 1){ 
      //   setFixedOpen(true)
      //   setVariableOpen(false)
      //   const newRows = fixedRow.map(item => ({
      //     from_date: item.from_date,
      //     to_date: item.to_date,
      //     percent: item.percent
      //   }));
      //   setFixedRow(newRows);
      //   }
    

  }

  if(rateType === 'variable'){
    
  if (Array.isArray(variableRow) && variableRow.length >= 1){ 
    setVariableOpen(true)
    setFixedOpen(false)

    const newRows = variableRow.map(item => ({
      from_date: item.from_date,
      to_date: item.to_date,
      hsn: item.hsn,
      hsn_percent: item.hsn_percent,
      sac: item.sac,
      sac_percent: item.sac_percent

     
    }));
    setVariableRow(newRows);
    }
    // if (Array.isArray(variableRow) && variableRow.length >= 1){ 
    //   setVariableOpen(true)
    //   setFixedOpen(false)
  
    //   const newRows = variableRow.map(item => ({
    //     from_date: item.from_date,
    //     to_date: item.to_date,
    //     hsn: item.hsn,
    //     hsn_percent: item.hsn_percent,
    //     sac: item.sac,
    //     sac_percent: item.sac_percent
  
       
    //   }));
    //   setVariableRow(newRows);
    //   }

  
}

  }

const handleCloseAb = () => {

  let hasError = false; // Track overall validation status
  let errorMessages = []; // Collect error messages
  
  rows.forEach((item, index) => {
    if( item.from_date === "" && item.to_date === "" && item.percent === ""  ){
      setAbModalOpen(false);
      setNaturebutton(true)
    }
    else{
      if (item.from_date === "" ) {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: From should not be null .`);
        hasError = true;
      }
      if (item.to_date === "") {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: To Date should not be null.`);
        hasError = true;
      }
      if (item.percent === "") {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: Percent should not be null `);
        hasError = true;
      }
     
      
    }
 
  });
  
  if (hasError) {
    alert(errorMessages.join("\n")); // Show all error messages in one alert
  } else {
    setAbModalOpen(false);
    setNaturebutton(true)
 // Proceed only if no errors
  }
 
};
const handleCloseFixed = () => {

  let hasError = false; // Track overall validation status
  let errorMessages = []; // Collect error messages
  
  fixedRow.forEach((item, index) => {
    if( item.from_date === "" && item.to_date === "" && item.percent === ""  ){
      setFixedOpen(false);
      setNaturebutton(true);
    }
    else{
      if (item.from_date === "" ) {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: From should not be null .`);
        hasError = true;
      }
      if (item.to_date === "") {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: To Date should not be null.`);
        hasError = true;
      }
      if (item.percent === "") {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: Percent should not be null `);
        hasError = true;
      }
     
      
    }
 
  });
  
  if (hasError) {
    alert(errorMessages.join("\n")); // Show all error messages in one alert
  } else {
    setFixedOpen(false);
    setNaturebutton(true);
 // Proceed only if no errors
  }
 

}
const handleCloseVar = () => {

  let hasError = false; // Track overall validation status
  let errorMessages = []; // Collect error messages
  
  variableRow.forEach((item, index) => {
    if( item.from_date === "" && item.to_date === "" && item.hsn === "" && item.hsn_percent === "" && item.sac === "" && item.sac_percent === ""  ){
      setVariableOpen(false);
      setNaturebutton(true)
    }
    else{
      if (item.from_date === "" ) {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: From Date should not be null .`);
        hasError = true;
      }
      if (item.to_date === "") {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: To Date should not be null.`);
        hasError = true;
      }
      if (item.hsn !== "" && item.sac !== "" ) {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: HSN or SAC Any one data should be Entered  `);
        hasError = true;
      }
      //   if (item.sac === "") {
      //   errorMessages.push(`Row ${JSON.parse(index + 1)}: SAC should not be null `);
      //   hasError = true;
      // }
      // if (item.hsn_percent === "") {
      //   errorMessages.push(`Row ${JSON.parse(index + 1)}: HSN Percent should not be null `);
      //   hasError = true;
      // }
    
      // if (item.sac_percent === "") {
      //   errorMessages.push(`Row ${JSON.parse(index + 1)}: SAC Percent should not be null `);
      //   hasError = true;
      // }
      if (item.hsn !== '' && item.hsn_percent === '') {
        errorMessages.push(`Row ${JSON.parse(index + 1)}: HSN Percent should not be null `);
        hasError = true;
          
      }
  
      if (item.sac !== '' && item.sac_percent === '') {
          errorMessages.push(`Row ${JSON.parse(index + 1)}: SAC Percent should not be null `);
        hasError = true;
      }
     
      
    }
 
  });
  
  if (hasError) {
    alert(errorMessages.join("\n")); // Show all error messages in one alert
  } else {
    setVariableOpen(false);
    setNaturebutton(true)
 // Proceed only if no errors
  }
 

}
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Taxation" parent="Taxation" title="Tax Element" />
      <Container fluid={true} className='Container1'>
        <div className='vendordiv1a'>
        <Col sm="12" xl="6" className='vendorcol2a'>
                <Card>
                <HeaderCard title={"Tax Element"} />  
                <CardBody>
                <Form className="theme-form nogird" onSubmit={handleSubmit}>
                  <div className='formdiv1'>
                  <div className='rowForm'>
                      <div>
                      <div className='formcol1'>
                          <FormGroup className='formin'>
                              <Label style={{fontWeight:"600"}} className="col-form-label" >Tax Element</Label>
                              <Input style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} onBlur={handleBlur} maxLength='25' id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={name} onChange={handleNameChange} required/>
                              {nameError1 && (
                              <div className="alert alert-danger mt-2">
                                  {nameError1}
                              </div>
                              )}
                              {nameError2 && (
                                   <div className="alert alert-danger mt-2">
                                      {nameError2}
                                  </div>
                              )}
                              {nameError3 && (
                                  <div className="alert alert-danger mt-2">
                                      {nameError3}
                                  </div>
                              )}
                          </FormGroup>
                          <FormGroup className='formin'>
                          <Label style={{fontWeight:"600"}} className="col-form-label" >Description</Label>
                          <Input onFocus={handleDescriptionFocus} maxLength="50" onBlur={handleBlur} onKeyDown={handleEnter} style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} id="desInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text"  value={description}  onChange={(e)=>setDescription(e.target.value)} />
                          </FormGroup>
                          {descriptionError1 && (
                          <div className="alert alert-danger">
                              {descriptionError1}
                          </div>
                          )}
                          {descriptionError2 && (
                              <div className="alert alert-danger">
                                  {descriptionError2}
                              </div>
                          )}
                          {errorMessage && (
                              <div className="alert alert-danger">
                                  {errorMessage}
                              </div>
                          )}
                      <FormGroup className='formin'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">GST Category</Label>
                          <Select
                              value={gstcategory}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onMenuOpen={() => {
                                setDropdownOwnOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownOwnOpen(false);
                              }}
                              onFocus={handleDescriptionFocus}
                              styles={customStyles}
                              onChange={(option) => setGstcategory(option)}
                              
                              options={typeOption}
                              filterOption={(option, inputValue) => 
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) => 
                                getHighlightedText(option.label, inputValue)
                              }
                              required
                          />
                      </FormGroup>
                      <FormGroup className='formin'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">GST Type</Label>
                          <Select
                              value={gsttype}
                              styles={customStyles}
                              onChange={(option) => setGsttype(option)}
                              onMenuOpen={() => {
                                setDropdownPurOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownPurOpen(false); 
                              }}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onFocus={handleDescriptionFocus}
                              options={impactOptions}
                              filterOption={(option, inputValue) => 
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) => 
                                getHighlightedText(option.label, inputValue)
                              }
                              required
                          />
                      </FormGroup>
                      </div>
                      <div className='formcol1'>
                          <FormGroup className='formin'>
                            <div className='checkbox checkbox-dark check1'>
                              <Input id='inline-1' type='checkbox' checked={isChecked} onChange={handleInCheckboxChange}/>
                              <Label for='inline-1' style={{fontWeight:"600"}} className="col-form-label" >
                                {Option}
                                <span className='digits'> {'Load on Inventory'}</span>
                              </Label>
                              {isChecked && (
                                // <Input onFocus={handleDescriptionFocus} maxLength="50"  onBlur={handleBlur} onKeyDown={handleEnter} style={{color:"black",borderColor: "#afafaf" ,height:"32px"}} id="desInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text"  value={loadonInventory}  onChange={handleChanges} />
                                <InputGroup>
                                        <Input onFocus={handleDescriptionFocus} maxLength="30"  onBlur={handleBlur} onKeyDown={handleEnter} style={{color:"black",borderColor: "#afafaf" ,height:"40px"}} id="desInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text"  value={loadonInventory}  onChange={handleChanges}  />
                                        <InputGroupText>{'%'}</InputGroupText>
                                </InputGroup>
                              )}

                            </div>
                          </FormGroup>
                          
                      <FormGroup className='formin'>
                      <Label style={{fontWeight:"600"}} className="col-form-label">Ledger account</Label>
                        <Select
                            value={under}
                            onFocus={handleDescriptionFocus}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={customStyles}
                            onChange={(option) => setUnder(option)}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={selectOptions}
                            filterOption={(option, inputValue) => {
                              const label = option.label.toLowerCase();
                              const [account, name] = label.split(' -- '); // Split by ' -- ' to get account and name
                              const cleanAccount = account.trim(); // Ensure account is clean of extra spaces
                              const cleanName = name ? name.trim() : ''; // Clean the name if it exists
                        
                              // Check if either account or name starts with the input value
                              return cleanAccount.startsWith(inputValue.toLowerCase()) || cleanName.startsWith(inputValue.toLowerCase());
                            }}
                            formatOptionLabel={(option, { inputValue }) => 
                              getHighlightedText(option.label, inputValue)
                            }
                            required
                        />
                      </FormGroup>
                      <FormGroup className='formin'>
                          <Label style={{fontWeight:"600"}} className="col-form-label">Is Abatement applicable ?</Label>

                          <div style={{display:"flex"}}>
                          <div style={{width:"90%"}} >
                          <Select
                              style 
                              value={abatement}
                              styles={customStyles}
                              onChange={handleAbatement}
                              onMenuOpen={() => {
                                setDropdownPurOpen(true);
                              }}
                              onMenuClose={() => {
                                setDropdownPurOpen(false);
                              }}
                              onBlur={handleBlur}
                              onKeyDown={handleEnter}
                              onFocus={handleDescriptionFocus}
                              options={abatementopt}
                              filterOption={(option, inputValue) =>
                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                              }
                              formatOptionLabel={(option, { inputValue }) =>
                                inputValue ? <span>{option.label}</span> : option.label
                              }
                              required
                          />
                          </div>
                          {viewbutton ? 
                          <div style={{width:"10%",padding:"8px"}}>
                            <button type="button" onClick={handleclick } style={{backgroundColor:"transparent",border:"none"}}><MdViewHeadline style={{fontSize:"20px"}}/></button>
                          
                          </div>: null}
                          </div>
                          <div style={{marginBottom :" 10px"}}>
                          {categoryName1 && (
                              <div className="alert alert-danger">
                                  {categoryName1}
                              </div>
                          )}
                          </div>
                          
                        
                      </FormGroup>
                      <FormGroup className='formin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label">Tax nature</Label>
                            <div  style={{display:"flex"}}>
                            <div className='m-t-10 m-checkbox-inline custom-radio-ml'>
                              <div className='radio radio-primary'>
                                <Input id='radioinline1' type='radio' name="rateType" value="fixed" checked={rateType === 'fixed'} onChange={() => (handleRateTypeChange('fixed'))} />
                                <Label className='mb-0' for='radioinline1'>
                                  {Option}
                                  <span className='digits'> {'Fixed'}</span>  
                                </Label>
                              </div>
                              <div className='radio radio-primary'>
                                <Input id='radioinline2' type='radio' name="rateType" value="variable" checked={rateType === 'variable'} onChange={() => (handleRateTypeChange('variable'))}/>
                                <Label className='mb-0' for='radioinline2'>
                                  {Option}
                                  <span className='digits'> {'Variable'}</span>
                                </Label>
                              </div>
                            </div>

                            {naturebutton ? <div style={{width:"10%",padding:"8px"}}>
                              <button type="button" onClick={handleclick1 } style={{backgroundColor:"transparent",border:"none"}}><MdViewHeadline style={{fontSize:"20px",backgroundColor:"transparent"}}/></button>
                            
                            </div> : null}
                            </div>
                            <div style={{marginBottom :" 30px"}}>
                            {categoryName && (
                              <div className="alert alert-danger">
                                  {categoryName}
                              </div>
                          )}
                            </div>
                            
                            
                          </FormGroup>
                      </div>
                      </div>
                    </div>
                  </div>
                    <div >
                        <div className="mt-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end"}}>
                        
                        <button ref={submitButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'save')}  className='btn2' color='primary' type='submit' >
                        {/* disabled={nameError1 || nameError2 || nameError3 || descriptionError1 || descriptionError2 || (name === '')} */}
                              {submitbtn}
                          </button>
                          <button className='btn1'  onClick={resetForm} ref={cancelButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'cancel')}>
                              Cancel
                          </button>
                        </div>
                      </div>
                </Form>
                </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6" className='vendorcol1a'>
                  <Card className=''>
                      <div style={{ display: "flex" }}>
                          <div className='headercl'>
                          <HeaderCard title="Tax Element List" />
                          </div>
                          <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
                              <Button color="danger" className='btncon' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
                          </div>
                      </div>
                  <CardBody>
                      <div className="table-responsive">
                          <InputGroup className="mb-3">
                              <Input
                                  type="text"
                                  placeholder="Search"
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                  className="form-control input-no-animation inputfoc"
                                  style={{borderColor: "#afafaf" }}
                              />
                              <InputGroupText >
                                  <FaSearch />
                              </InputGroupText>
                          </InputGroup>
                          <DataTable
                                columns={columns}
                                data={paginatedData}
                                selectableRows
                                selectableRowsSelected={selectedIds}
                                onSelectedRowsChange={handleCheckboxChange}
                                clearSelectedRows={clearRows}
                                pagination
                                paginationServer
                                customStyles={customStylesTable}
                                striped={false}
                                paginationTotalRows={categories.length}
                                paginationPerPage={rowsPerPage}
                                progressPending={loading}
                                autoWidth={true}
                                paginationComponentOptions={{
                                  noRowsPerPage: true, // Disable option to change rows per page
                                }}
                                onChangePage={handlePageChange} // Handle pagination
                                highlightOnHover
                          />
                      </div>
                  </CardBody>
                </Card>
            </Col>
        </div>
        <div>
          {abModalOpen ?  
          <div className="popup">
            <div className='popup-inner'>
            <div className='popcls'>
              <div className='popclsdiv1'>
                <h5>Abatement</h5>
              </div>
              <div className='popclsdiv2'>
                <button className='popclsbtn' type='buttom' onClick={handleCloseAb}><IoMdClose className='clsico' color='red'/></button>
              </div>
            </div>
            
              <div className="table-container">
                  <table style={{ width: "100%" }}>
                      <thead>
                          <tr>
                              <th>From Date</th>
                              <th>To Date</th>
                              <th>Percent</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          {rows.map((row, index) => (
                              <tr key={index}>
                                  <td>
                                      <Input
                                          type="date"
                                          name="from_date"
                                          value={row.from_date}
                                          onChange={(e) => handleInputChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                      <Input
                                          type="date"
                                          name="to_date"
                                          value={row.to_date}
                                          onChange={(e) => handleInputChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                     
                                      <InputGroup>
                                        <Input  type="number"
                                          name="percent"
                                          value={row.percent}
                                          
                                          onChange={(e) => handleInputChange(index, e)}
                                          required/>
                                        <InputGroupText>{'%'}</InputGroupText>
                                </InputGroup>
                                  </td>
                                  <td>
                                  <button title='Delete' className="vendordele1" onClick={() =>{handleDeleteRow(index)}}>
                                    <IoTrash className="vendortrash" />
                                  </button>
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
                  <button className='btn1a' onClick={handleAddRow}>Add Row</button>
                  <div className='popbtn'>
                    <Button className='btn2' color='primary' onClick={handleAbatementsubmit} type='buttons'>Submit</Button>{' '}
                    {/* <Button className='btn1' type="button" onClick={handleCancelAbatement}>Cancel</Button> */}
                  </div>
              </div>
            </div>
          </div>
          :null}
        </div>
        <div>
          {fixedOpen ?  
          <div className="popup">
            <div className='popup-inner'>
              <div className='popcls'>
                <div className='popclsdiv1'>
                  <h5>Fixed Nature</h5>
                </div>
                <div className='popclsdiv2'>
                  <button className='popclsbtn' type='buttom' onClick={handleCloseFixed}><IoMdClose className='clsico' color='red'/></button>
                </div>
              </div> 
              
              <div className="table-container">
                  <table style={{ width: "100%" }}>
                      <thead>
                          <tr>
                              <th>From Date</th>
                              <th>To Date</th>
                              <th>Percent</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          {fixedRow.map((row, index) => (
                              <tr key={index}>
                                  <td>
                                      <input
                                          type="date"
                                          name="from_date"
                                          style={{ height: '35px' }}
                                          value={row.from_date}
                                          onChange={(e) => handleFixedChange(index, e)}
                                          required
                                      />
                                  </td>
                                  <td>
                                      <input
                                          type="date"
                                          name="to_date"
                                          style={{ height: '35px' }}
                                          value={row.to_date}
                                          onChange={(e) => handleFixedChange(index, e)}
                                          required
                                          
                                      />
                                  </td>
                                  <td>
                                  <InputGroup>
                                        <Input  type="number"
                                          name="percent"
                                          value={row.percent}
                                          onChange={(e) => handleFixedChange(index, e)}
                                          required

                                          />
                                        <InputGroupText>{'%'}</InputGroupText>
                                </InputGroup>
                                      {/* <Input
                                          type="number"
                                          name="percent"
                                          value={row.percent}
                                          onChange={(e) => handleFixedChange(index, e)}
                                          placeholder="%"
                                          required
                                      /> */}
                                  </td>
                                  <td>
                                  <button title='Delete' className="vendordele1" onClick={()=>{handleFixedDeleteRow(index)}}>
                                    <IoTrash className="vendortrash" />
                                  </button>
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
                  <button className='btn1a' onClick={handleFixedAddRow}>Add Row</button>
                  <div className='popbtn'>
                    <Button className='btn2' color='primary' onClick={handleFixed} type='buttons'  >Submit</Button>{' '}
                    {/* <Button className='btn1' type="button" onClick={handleCancelFixed}>Cancel</Button> */}
                  </div>
              </div>
            </div>
          </div>
          :null}
        </div>
        <div>
        {variableOpen ? (
        <div className="popup">
          <div className='popup-inner'>
            <div className='popcls'>
                <div className='popclsdiv1'>
                  <h5>Variable Nature</h5>
                </div>
                <div className='popclsdiv2'>
                  <button className='popclsbtn' type='buttom' onClick={handleCloseVar}><IoMdClose className='clsico' color='red'/></button>
                </div>
              </div>
              
            <div className="table-container">
              
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>HSN</th>
                    <th>Percent</th>
                    <th>SAC</th>
                    <th>Percent</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {variableRow.map((row, index) => (
                    <tr key={index}>
                      <td>
                      <input
                        type="date"
                        name="from_date"
                        style={{ height: '35px' }}
                        value={row.from_date}
                        onChange={(e) => handleVariableChange(index, e)}
                        required
                      />

                      </td>
                      <td>
                        <input  type="date"
                          name="to_date"
                          style={{ height: '35px' }}
                          value={row.to_date}
                          onChange={(e) => handleVariableChange(index, e)}
                          required />
                      </td>
                      <td>
                      {/* <Select
                            value={hsnOptions.find(option => option.value === row.hsn)}
                            // onFocus={handleDescriptionFocus}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={popupopStyles}
                            onChange={(option) => handleSelectHsnChange(index, option, 'hsn')}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={hsnOptions}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            formatOptionLabel={(option, { inputValue }) => 
                              getHighlightedText(option.label, inputValue)
                            }
                            
                        /> */}
                         <select
                            type="number"
                            name='hsn'
                            style={{height:"35px" , padding:0,width:"100px"}}
                            value={row.hsn}
                            onChange={(e) => handleVariableChange(index, e)}
                            
                        >
                            <option value={''} >Select Type</option>
                            {hsnOptions.map((whtaxtype)=>(
                                <option key={whtaxtype.value} value={whtaxtype.value}>{whtaxtype.label}--{whtaxtype.value}</option>
                            ))}
                        </select>
                      </td>
                      <td>
                        
                        <InputGroup>
                                        <Input type="number"
                          name="hsn_percent"
                          value={row.hsn_percent}
                          id = "hsnpercent"
                          
                          onChange={(e) => handleVariableChange(index, e)}
                          />
                                        <InputGroupText>{'%'}</InputGroupText>
                                </InputGroup>
                      </td>
                      <td>
                      {/* <Select
                            value={sacOptions.find(option => option.value === row.sac)}
                            // onFocus={handleDescriptionFocus}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={popupopStyles}
                            onChange={(option) => handleSelectSacChange(index, option, 'sac')}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={sacOptions}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            formatOptionLabel={(option, { inputValue }) => 
                              getHighlightedText(option.label, inputValue)
                            }
                            
                        /> */}
                        <select
                            type="number"
                            name='sac'
                            style={{height:"35px" , padding:0,width:"100px"}}
                            value={row.sac}
                            onChange={(e) => handleVariableChange(index, e)}
                            
                        >
                            <option value={''}  >Select Type</option>
                            {sacOptions.map((whtaxtype)=>(
                                <option key={whtaxtype.value} value={whtaxtype.value}>{whtaxtype.label}</option>
                            ))}
                        </select>
                      </td>
                      <td>
                       
                        <InputGroup>
                                        <Input  type="number"
                          name="sac_percent"
                          id="sacpercent"
                          value={row.sac_percent}
                          
                          onChange={(e) => handleVariableChange(index, e)}/>
                                        <InputGroupText>{'%'}</InputGroupText>
                                </InputGroup>
                      </td>
                      <td>
                        <button title='Delete' className="vendordele1" onClick={() => handlVariableDeleteRow(index)}>
                          <IoTrash className="vendortrash" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
              <button className='btn1a' onClick={handleVariableAddRow}>Add Row</button>
                  <div className='popbtn'>
                    <Button className='btn2' color='primary' onClick={handleVariable} type='buttons'  >Submit</Button>{' '}
                    {/* <Button className='btn1' type="button" onClick={handleCancelVaraible}>Cancel</Button> */}
                  </div>
            </div>
          </div>
        </div>
      ) : null}
        </div>
      </Container>
    </Fragment>
  );
}

export default Taxelement;
