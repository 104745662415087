import React, {Fragment, useEffect, useState, useRef} from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Table, Input, Button,InputGroup ,InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs ,H2, H3, H4, H5,H6} from '../../../../AbstractElements';
import { IoArrowBackOutline, IoArrowForward  } from "react-icons/io5";
import HeaderCard from '../../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import classNames from "classnames";
import { Category, FontSize, FontWeight, MarginLeft, MarginRight, MarginTop } from '../../../../Constant';

function Taxdesign(){
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [under, setUnder] = useState(null);
    const [tax_element1, setTax_element1] = useState([]);
    const vendorCodeRef = useRef(null); // Ref for VendorCode input
    const [categories, setCategories] = useState([]);
    const [clearRows, setClearRows] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectnatureOptions, setSelectnatureOptions] = useState([]);
    const [selectbasisOptions, setSelectbasisOptions] = useState ([]);
    const [selectelementOptions, setSelectelementOptions] = useState([]);
    const [nameError1 , setNameError1] = useState(null);
    const [descriptionError1, setDescriptionError1] = useState(null);
    const [nameError2 , setNameError2] = useState(null);
    const [nameError3 , setNameError3] = useState(null);
    const [descriptionError2, setDescriptionError2] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [submitbtn, setSubmintbtn] =useState('Save');
    const [opt1 , setOpt1] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [shouldFocusName, setShouldFocusName] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const cancelButtonRef = useRef(null);
    const submitButtonRef = useRef(null);
    const [submittedRows, setSubmittedRows] = useState([]);
    const [filteredPaymentBase, setFilteredPaymentBase] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [taxcluster, setTaxCluster] = useState('');
    // const [tax_design_number, setTaxDesignNumber] = useState('');
    const [grid_view, setGridView] = useState(false);
    const [addedRows, setAddedRows] = useState([]);
    const [checked, setChecked] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [taxelementOptions, setTaxelementOptions] = useState([]);
    const [previousSelectedOptions, setPreviousSelectedOptions] = useState([]);
    const [taxelement, setTaxelement] = useState([]);
    // const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [currentRowIndex, setCurrentRowIndex] = useState(0);
    const [selectedRowOptions, setSelectedRowOptions] = useState([]);
    const [rows, setRows] = useState([{ 
      id:'',
      index: '',
      tax_element: '',
      description: '',
      chargeable_basis: '',
      calculation_factors: [],
      calculation_factors_name:''
       }]);
    const load_on_inventory = [{ value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' }];
  
    const rowsPerPage = 10;
    const customStylesTable = {
      header: {
        style: {
          minHeight: '40px',
          backgroundColor: '#f0f0f0',
          color: '#333',
        },
      },
      headRow: {
        style: {minHeight: '40px',
          backgroundColor: '#f0f0f0',
        },
      },
      headCells: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          textAlign: 'left',
          color: '#000',
        },
      },
      rows: {
        style: {
          minHeight: '40px',
          borderBottom: '1px solid #ddd',
        },
      },
      cells: {
        style: {
          textAlign: 'left',
          fontSize:'12px'
        },
      },
    };
    
    const customStyles = {
      control: (provided, state) => ({
          ...provided,
          minHeight: '30px',
          borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
          boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
          '&:hover': {
              borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
          },
      }),
      placeholder: (provided) => ({
          ...provided,
          fontSize: '12px', // Change the font size for the placeholder
          color: '#aaa', // Optional: Change placeholder color
        }),
      valueContainer: (provided) => ({
          ...provided,
          height: '30px', // Adjust the container height
          padding: '0 6px',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0px', // Remove default margin for the input inside the select
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px', // Adjust the indicator container height
        }),

        header: {
          style: {
            minHeight: '40px', // Adjust header height
            backgroundColor: '#f0f0f0', // Light grey background for the header
            color: '#333', // Text color
          },
        },
        headRow: {
          style: {
            minHeight: '40px',
            backgroundColor: '#f0f0f0', // Grey color for the table header row
          },
        },
        headCells: {
          style: {
    
            fontSize: '12px', // Font size for header cells
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#000', // Header text color
          },
        },
        rows: {
          style: {
            minHeight: '40px', // Minimum height of rows
            borderBottom: '1px solid #ddd', // Bottom border for rows
          },
        },
        cells: {
          style: {
            fontSize: '12px',
            textAlign: 'left',
          },
        },
  };
  
  // const paginatedData = Array.isArray(submittedRows)
  // ? submittedRows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
  // : [];

  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];

  console.log(filteredPaymentBase)
  const highlightFirstOccurrence = (text, searchTerm) => {
    const index = text.indexOf(searchTerm); // Find the first occurrence
    if (index !== -1) {
      return [{ start: index, end: index + searchTerm.length }];
    }
    return [];
  };
  
  const VendorNameCell = ({ taxcluster_name, searchTerm }) => (
    <Highlighter
      highlightClassName="highlighted-text"
      searchWords={[]} // Not used since we provide custom chunks
      autoEscape={true}
      textToHighlight={taxcluster_name}
      findChunks={() => highlightFirstOccurrence(taxcluster_name.toLowerCase(), searchTerm)}
    />
  );

  const columns = [
    {
      name: (<div className='dataTableDiv'>SN</div>),
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      width:'70px',
      cell:(row, index) =>(
        <div className='dataTableDiv' style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name: (<div className='dataTableDiv'>Tax Cluster</div>),
      selector: row => row.taxcluster_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
        <VendorNameCell taxcluster_name={row.taxcluster_name} searchTerm={searchTerm.toLowerCase()} />
        </div>
      ),
    },
    {
      name: (
        <div className='dataTableDiv'>Description</div>
      ),
      selector: row => row.description,
      width:"auto",
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
        {row.description}
        </div>
      ),
    },
    {
      name: (<div className='dataTableDiv'>Action</div>),
      center: true,
      width:'100px',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];
    useEffect(() => {
      if (searchTerm.trim() === '') {
        setFilteredCategories(categories || []);
      } else {
        const safeCategories = Array.isArray(categories) ? categories : [];
        const filteredData = safeCategories.filter(item =>
          item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCategories(filteredData);
      }
    }, [searchTerm, currentPage, categories]);
  
    useEffect(() => {
      fetchCategories(currentPage);
      fetchSelectOptions();
      fetchTaxnatureOptions();
      fetchBasisOptions();
      fetchElementOptions();
      fetchTaxelementOptions();
    }, [currentPage,searchTerm]); 
   const handlePopup = () =>{
    if(under === null){
      alert("Please select the Tax cluster.")
    }else{
      setIsOpen(true);
    }
    // setRows([taxElementdata]);
   }
    const fetchCategories = async (page) => {
      setLoading(true);
      try {
        let response;
        if (searchTerm) {
            response = await axiosInstance.get(`taxation/taxdesign/search/?name=${searchTerm}`);
        } else {
            response = await axiosInstance.get(`taxation/taxdesign/get/`);
        }
        setCategories(response.data);
        setFilteredCategories(response.data); 
      } catch (error) {
        if (error.response && error.response.status === 404 && page > 1) {
          handlePageChange(page - 1);
        } else {
          console.error("Error fetching categories:", error);
        }
      }finally {
        setLoading(false);
      }
    };
    const handlePageChange = (page) => {
      setCurrentPage(page);
      fetchCategories(page);
    };
    const fetchSelectOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('taxation/taxcluster/get/');
        console.log(editId,'snckdsvv');
        const opt = response.data.map(category => ({
          value: category.id,
          label: category.name,
          description: category.description,
          taxElement: category.taxelement,
        }));
        setOpt1(opt);
        console.log(opt,'options');
        const filteredOptions = response.data
        .filter(category => category.id !== editId)
        .map(category => ({
          value: category.id,
          label: category.name,
          description: category.description,
          taxElement: category.taxelement,
        }));
        console.log(filteredOptions,'tax element-==--=-=-=-=')
        setSelectOptions([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };
    const fetchTaxnatureOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('taxation/gsttaxnature/get/');
        console.log(editId,'snckdsvv');
        const filteredOptions = response.data
        .filter(category => category.id !== editId) // Exclude the category being edited
        .map(category => ({
          value: category.id,
          label: category.name,
        }));
        setSelectnatureOptions([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };

    const fetchBasisOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('taxation/gstchargablebasis/get/');
        console.log(editId,'snckdsvv');
        const filteredOptions = response.data
        .filter(category => category.id !== editId) // Exclude the category being edited
        .map(category => ({
          value: category.id,
          label: category.name,
        }));
        setSelectbasisOptions([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      } 
    };

    const fetchElementOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('taxation/taxelement/get/');
        console.log(editId,'snckdsvv');
        const opt = response.data.map(category => ({
          value: category.id,
          label: category.tax_element,
          description: category.description
        }));
        setOpt1(opt);
        console.log(opt,'options');
        const filteredOptions = response.data
        .filter(category => category.id !== editId)
        .map(category => ({
          value: category.id,
          label: category.tax_element,
          description: category.description
        }));
        setSelectelementOptions([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };

   const fetchTaxelementOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/taxelement/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId)
      .map(category => ({
        value: category.id,
        label: category.tax_element,
        description: category.description,
      }));
      console.log(filteredOptions,'Tax element options');
      setTaxelementOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const rowEmptyFieldsMessages = rows
      .map((row, index) => {
      const missingFields = [];
      if (!row.index) missingFields.push("Index");
      if (!row.chargeable_basis) missingFields.push("Chargeable Basis");
      if (!Array.isArray(row.calculation_factors) || row.calculation_factors.length === 0) {
        missingFields.push("Calculation Factors");
      }
      if (missingFields.length > 0) {
          return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
      }
      return null;
      })
      .filter(Boolean);
      if (rowEmptyFieldsMessages.length > 0) {
        alert(`Tax Details Data:\n${rowEmptyFieldsMessages.join("\n")}`);
    }else{
      console.log(under," under submit")
      const payload = {
        taxcluster: under.value,
        description,
        grid_view: rows,
      };
      setNameError1(null);
      setDescriptionError1(null);
      try {
        
        if (isEditing) {
          await axiosInstance.put(`taxation/taxdesign/${editId}/`, payload);
          setIsEditing(false);
        } else {
          await axiosInstance.post('taxation/taxdesign/create/', payload);
          setErrorMessage(null);
        }
        fetchCategories(currentPage);
        fetchSelectOptions();
        fetchTaxnatureOptions();
        fetchBasisOptions();
        fetchElementOptions();
        fetchTaxelementOptions();
        setDropdownVisible(false);
        setSelectedOptions([]);
        setRows();
        resetForm();
       
      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.data) {
          const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
          const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
          const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
          console.log(nameError, 'Name Error');
          console.log(descriptionError, 'Description Error');
          if (nameError && descriptionError) {
          } else if (nameError) {
            setNameError1(nameError)
          } else if (descriptionError) {
            setDescriptionError1(descriptionError);
          } else if (existsError) {
            setErrorMessage(existsError);
   
          }
        } else {
          setErrorMessage('An error occurred while submitting the form.');
        }
      }
    }
    };
  
    const handleUpdateClick = (category) => {
      resetForm();
      setNameError1(null);
      setNameError3(null);
      setEditId(category.id);
      setNameError2(null);
      setDescriptionError2(null);
      setName('');
      setDescription('');
      // setTaxDesignNumber('');
      setRows('')
      setIsEditing(false);
      // setUnder(null);
      setNameError1(null);
      setClearRows(!clearRows);
      setSubmintbtn('Save')
      setDescriptionError1(null);
      setErrorMessage('');
      setNameError1(null);
      setDescriptionError1(null);
      setUnder(category.taxcluster_name);
      setDescription(category.description);
      // setTaxDesignNumber(category.tax_design_number);
      setSubmintbtn('Update');
      console.log(category.id,'nbhgvcsfdnhvbjmb');
      setUnder({ value: category.taxcluster, label: category.taxcluster_name });
      setIsEditing(true);
      fetchSelectOptions(category.id);
      fetchTaxnatureOptions(category.id);
      fetchBasisOptions(category.id);
      fetchElementOptions(category.id);
      setErrorMessage('');
      const extractedRows = category.grid_view.map((row,index) => {
        const calculationFactors = row.calculation_factors.map((factor) => factor.tax_element).join(' + ');
        const calculationFactorsId = row.calculation_factors.map((factor) => factor.tax_element_id);
         // Create an array for the selected options (values, labels, descriptions)
        const selectedOptions = row.calculation_factors.map((factor) => ({
            value: factor.tax_element_id, // The value should be tax_element_id
            label: factor.tax_element,    // The label should be tax_element
            description: factor.tax_element_description || '', // The description can be tax_element_description (if exists)
        }));

        setSelectedRowOptions((prevSelectedRowOptions) => {
          // Ensure you're adding the options in the correct index position
          const updatedSelectedRowOptions = [...prevSelectedRowOptions];

          // Set the index starting from 0 and increment for each row
          const rowIndex = index; // index from map ensures it's correct, starting from 0

          // Initialize the array for the row if not already set
          if (!updatedSelectedRowOptions[rowIndex]) {
              updatedSelectedRowOptions[rowIndex] = [];
          }

          // Add the selected options to the row at rowIndex
          updatedSelectedRowOptions[rowIndex] = selectedOptions;

          return updatedSelectedRowOptions;
      });

        return {
          id:row.id,
          index: row.index || '', // If there's no index, set it to an empty string or 0
          tax_element: row.tax_element_id,
          tax_elementName: row.tax_element_name || '', // Assuming `tax_element_name` exists
          description: row.tax_element_description, // Use the found description
          chargeable_basis: row.chargeable_basis_id|| '', // If not available, default to an empty string
          calculation_factors:calculationFactorsId,
          calculation_factors_name: calculationFactors, // Same for calculation factors
          tax_design_details_number: row.tax_design_details_number || '', // Ensure default if missing
        };
  
      });
      
      console.log(extractedRows,'i+_++_=-=-=-=-=-=-=-=-=uvhriughr');
      setRows(extractedRows);
    };
  
    const resetForm = () => {
      setNameError2(null);
      setNameError3(null);
      setDescriptionError2(null);
      setName('');
      setDescription('');
      setIsEditing(false);
      setUnder(null);
      // setTaxDesignNumber('');
      setNameError1(null);
      setClearRows(!clearRows);
      setSubmintbtn('Save')
      setDescriptionError1(null);
      setEditId(null);
      setErrorMessage('');
      setSelectedRowOptions([]);
      setRows([{ 
        id:'',
        index: '',
        tax_element: '',
        description: '',
        chargeable_basis: '',
        calculation_factors: [],
        calculation_factors_name:'',
         }]);
    };
    
    const handleCheckboxChange = (selectedRows) => {
      setSelectedIds(selectedRows.selectedRows.map(row => row.id));
  };
    
    const handleDelete = async () => {
      try {
          await axiosInstance.delete('taxation/taxdesign/delete/', {
            data: { ids: selectedIds },
          });
          fetchCategories(currentPage);
          setSelectedIds([]);
          fetchSelectOptions();
          fetchTaxnatureOptions();
          fetchBasisOptions();
          fetchElementOptions();
          resetForm();
        // }
      } catch (error) {
        console.error('Error:', error.response.data);
        const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
        Swal.fire('Error!',errorMessage , 'error');
      }
    };
  
    const handleDeleteCategory = async (id) => {
       {
        try {
          await axiosInstance.delete(`taxation/taxdesign/${id}/`);
          fetchCategories(currentPage);
        } catch (error) {
          console.error('Error deleting category:', error);
          Swal.fire('Error!', 'It cannot be deleted because it is assigned to another master.', 'error');
        }
  
        setSelectedIds([]);
        fetchSelectOptions();
        fetchTaxnatureOptions();
        fetchBasisOptions();
        fetchElementOptions();
        resetForm();
      }
    };
  
    const getHighlightedText = (text, highlight) => {
      const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={index} style={{fontWeight:'bold' }}>{part}</span>
            ) : (
              part
            )
          )}
        </span>
      );
    };
    const validateName = (value) => {
      console.log(value,'kjhvckjsdnv');
      
     if (value.match('  ')) {
        setNameError1('Continuous spaces are not allowed ');
        return false;
      }  else if(value.length === 30){
        setNameError2(null);
      }else {
        setNameError1(null);
        return true;
      }
    };
  
    const handleNameChange = (e) => {
        let value = e.target.value;
        value = value.replace(/\s+/g,' ').trimStart();
        setName(value);
        validateName(value); 
    };
  
  const handleEnter = (event) => {
    if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
      event.target.focus();  
    } 
    else if (event.keyCode === 13 && event.target.form) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        event.target.value = event.target.value.trim();
      }
      if (dropdownOpen) {
        return; 
      } else {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form.elements, event.target);
        const nextElement = form.elements[index + 1];
        if (nextElement) {
          nextElement.focus();
        }
        event.preventDefault();
      }
    }
    const { value } = event.target;
    if (
      (event.key === ' ' && value.length === 0) || 
      (event.key === ' ' && value.endsWith(' ')) || 
      (event.key === ' ' && value.includes('  '))
    ) {
      event.preventDefault();
      return;
    }
  };
  
  const handleArrowNavigation = (event, buttonType) => {
    if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  };
    const handleBlur = (e) => {
      setNameError2(null);
      setDescriptionError2(null);
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
        
        if (nameError1) {
            e.target.focus();
        }else if (descriptionError1) {
          e.target.focus(); 
        }
      }
    };
    const handleDescriptionFocus = () => {
      // Perform name validation when switching to the description field
      if(isEditing){
        const otherCategories = categories.filter(category => category.id !== editId);
        console.log(otherCategories,'is editing');
        if (Array.isArray(otherCategories) && otherCategories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())) {
          setShouldFocusName(true);
          setNameError3('Item category with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
        }, 0);
        }
        else {
          setNameError3(null);
        }
        
      }else{
        if(Array.isArray(categories) && categories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())){
          setShouldFocusName(true);
          setNameError3('Item category with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
          }, 0);
          return false;
        }else {
          setNameError3(null);
        }
  
      }
    };

const handleAddRow = (event) => {
    event.preventDefault(); 
    const newRow = {
      index: '',
      tax_element: '',
      description: '',
      chargeable_basis: '',
      calculation_factors: '',
    };
    console.log('Current Rows:', rows);
};
// Define your handleFocusForChargeableBasis
// const handleFocusForChargeableBasis = (index, e) => {
//   // Retrieve the current row based on the index
//   const currentRow = tax_element1[index];
//   console.log("You can modify this condition as per your requirements", e);
//   // const { name, value } = e.target;
//   const name = "tax_element";
//   const value = e;
//     const updatedTaxElements = [...rows];  // Make a copy of the tax_element array
//     updatedTaxElements[index] = {
//       ...updatedTaxElements[index],
//       [name]: value,  // Dynamically update the changed field
//     };
//   setRows(updatedTaxElements);

//   // Check if there is a tax_element value and perform custom logic
//   // if (currentRow.tax_element) {
//   //   // For example, set the chargeable_basis to the tax_element value or based on some other logic
//   //   console.log('Tax Element:', currentRow.tax_element);
    
//   //   // Optionally, set the chargeable_basis value based on the tax_element
//   //   // For example, if tax_element value is 'Sample1', set the chargeable_basis value to 'Category1'
//   //   if (currentRow.tax_element === 'Sample1') {
//   //     currentRow.chargeable_basis = 'Category1'; // You can modify this condition as per your requirements
//   //     console.log("You can modify this condition as per your requirements");
//   //   }
//   // }
// };

const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    // Update the rows state with the updated rows array
    console.log(rows,"rows tax_element");

    setRows(updatedRows);
};


// const handleDeleteRow = (index) => {
//   const updatedRows = rows.filter((_, i) => i !== index);
//   const reindexedRows = updatedRows.map((row, i) => ({
//     ...row,
//     index: i + 1, // Reindex starting from 1
//   }));
  
//   setRows(reindexedRows);
// };

const handleDeleteRow = (index) => {
  const newRows = [...rows];
  newRows.splice(index, 1); // Remove the row at the specified index
  setRows(newRows);
  if(newRows.length === 0){
      setRows([
          { 
            index: '',
            tax_element: '',
            description: '',
            chargeable_basis: '',
            calculation_factors: '',
            calculation_factors_name: '',
            // load_checked:false,
            // load_on_inventory: 0,
            // tax_nature: '',
            // tax_design_details_number: '',
          }
      ]);
  }
  
};

const handleAbatementsubmit = (event) => {
  event.preventDefault();
  console.log(rows);
  setSubmittedRows([...submittedRows, ...rows]); 
  setRows([{
        index: '',
        tax_element: '',
        description: '',
        chargeable_basis: null,
        calculation_factors: null,
        // load_checked:false,
        // load_on_inventory: 0,
        // tax_nature: '',
        // tax_design_details_number: '',
  }]);
  setIsOpen(false); 
};

// const handleSelectChange = (selectOptions) => {
//   setUnder(selectOptions); 
//   setTax_element1([selectOptions]);
//   const extractedRows = selectOptions.taxElement.map((taxItem, index) => ({
//     index: '', // Assuming index starts from 1
//     tax_element: taxItem.id,
//     tax_elementName: taxItem.tax_element,
//     description: taxItem.description,
//     chargeable_basis: '', // Set as required, can be populated based on tax_element
//     calculation_factors: '', // Set as required, can be populated based on tax_element
//     calculation_factors_name: '',
//   }));
//   setRows(extractedRows);
//   console.log(selectOptions,"1234567890-_--===-===");
//   if (selectOptions) {
//     setDescription(selectOptions.description); 
//   }
// };

const handleSelectChange = (selectOptions) => {
  console.log(selectOptions.value,'tax cluster_______')
  if (!selectOptions) return;
  const otherVendors = isEditing ? categories.filter(cluster => cluster.id !== editId) : categories;
  const isDuplicateCode = otherVendors.some(
      item => item.taxcluster === selectOptions.value
  );
  if (isDuplicateCode) {
      setNameError2(`TaxCluster with this name '${selectOptions.label}' already exists.`);
      setUnder(null);
      setDescription(null);
      vendorCodeRef.current.focus(); 
      setTimeout(() => {
          const nameInput = document.getElementById('codeInput');
          if (nameInput) {
              nameInput.focus();
          }
      }, 0);
      return; // Exit function to prevent further processing
  } else {
      setNameError2(null); // Clear the error if no duplicate
  }

  // Update the state variables
  setUnder(selectOptions); // Update under with selected option (selectOptions)
  setTax_element1([selectOptions]); // Set the tax_element1 state with selected options

  // Extract rows from taxElement in selectOptions
  const extractedRows = selectOptions.taxElement.map((taxItem) => ({
      index: '', // Assuming index starts from empty or you can set a specific index if required
      tax_element: taxItem.id, // Set tax_element ID
      tax_elementName: taxItem.tax_element, // Set tax_element name
      description: taxItem.description || '', // Set description (default to empty if not provided)
      chargeable_basis: '', // Set as required, can be populated based on tax_element
      calculation_factors: '', // Set as required, can be populated based on tax_element
      calculation_factors_name: '', // Set as required
  }));

  // Update rows state
  setRows(extractedRows);
  
  // Update description if selectOptions has a description
  if (selectOptions.description) {
      setDescription(selectOptions.description);
  }
  
  // Debug log to show the selected options
  console.log(selectOptions, "1234567890-_--===-===");
};


const addRow = () => {
  const newRow = {
    tax_element: '',
    description: '',
    load_checked:false,
    load_on_inventory: 0,
    tax_nature: '',
    chargeable_basis: '',
    calculation_factors: '',
    tax_design_details_number: '',
    // index: rows.length + 1,
    index: '',
  };
  setRows((prevRows) => [...prevRows, newRow]);
        setAddedRows((prevAddedRows) => [...prevAddedRows, newRow]);
  if (isNaN(newRow.index) || newRow.index <= 0) {
    console.error("Invalid index value:", newRow.index);
    return;  
  }

  setRows([...rows, newRow]);
};

// const handleTaxDesignNumberChange = (e) => {
//   setTaxDesignNumber(e.target.value);
// };

  const handleSave = ()=>{
    console.log(rows, "check is empty")
    const rowEmptyFieldsMessages = rows
    .map((row, index) => {
    const missingFields = [];
    if (!row.index) missingFields.push("Index");
    // if (!row.description) missingFields.push("Description");
    // if (!row.load_on_inventory) missingFields.push("Load On Inventory");
    // if (!row.tax_nature) missingFields.push("Tax Nature");
    if (!row.chargeable_basis) missingFields.push("Chargeable Basis");
    if (!Array.isArray(row.calculation_factors) || row.calculation_factors.length === 0) {
      missingFields.push("Calculation Factors");
    }
    // if (!row.tax_design_details_number) missingFields.push("Tax Design Details Number");

    if (missingFields.length > 0) {
        return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
    }
    return null;
    })
    .filter(Boolean);
    if (rowEmptyFieldsMessages.length > 0) {
      alert(`Tax Details Data:\n${rowEmptyFieldsMessages.join("\n")}`);
  }else{
    setIsOpen(false);
  }
  }

  const handleClose =()=>{
    // setRows((prevRows) => prevRows.filter((rows) => !addedRows.includes(rows)));
    // setAddedRows([]); // Reset added rows
    setIsOpen(false);
  }
    
  const handleClose1 = () => {
    setSelectedRowOptions(previousSelectedOptions); // Restore original (saved) options
    setDropdownVisible(false); // Close the dropdown
  };

  const handleCheckbox = (e, label, value, description, rowIndex) => {
    setSelectedRowOptions((prevState) => {
      // Initialize updatedRowOptions if prevState is not an object
      const updatedRowOptions = { ...prevState };

      // Ensure the rowIndex exists in updatedRowOptions
      if (!updatedRowOptions[rowIndex]) {
        updatedRowOptions[rowIndex] = [];
      }

      // Add or remove the selected element from the row depending on checkbox state
      if (e.target.checked) {
        updatedRowOptions[rowIndex] = [
          ...updatedRowOptions[rowIndex],
          { value, label, description },
        ];
      } else {
        updatedRowOptions[rowIndex] = updatedRowOptions[rowIndex].filter(
          (item) => item.value !== value
        );
      }

      return updatedRowOptions;
    });
  };

  const handleSelectAll = (e, rowIndex) => {
    const isChecked = e.target.checked;

    setSelectedRowOptions((prevState) => {
      const updatedRowOptions = { ...prevState };

      // If "select all" is checked for the current row (rowIndex)
      if (isChecked) {
        updatedRowOptions[rowIndex] = taxelementOptions.map(option => ({
          value: option.value,
          label: option.label,
          description: option.description,
        }));
      } else {
        // If "select all" is unchecked, clear the selected options for this row
        updatedRowOptions[rowIndex] = [];
      }

      return updatedRowOptions;
    });
  };

  const handleSave1 = (rowIndex) => {
    console.log(selectedRowOptions, "checkbox selections");

    setRows((prevRows) => {
      const updatedRows = prevRows.map((row, index) => {
        if (index === rowIndex) {
          const selectedOptionsForRow = selectedRowOptions[rowIndex] || [];

          return {
            ...row,
            calculation_factors: selectedOptionsForRow.map(opt => opt.value),
            calculation_factors_name: selectedOptionsForRow.map(opt => opt.label).join(" + ") || "",
          };
        }
        return row;
      });

      return updatedRows;
    });

    // Save the previously selected options for that row
    setPreviousSelectedOptions({ ...selectedRowOptions });

    // Hide the dropdown
    setDropdownVisible(false);
  };

  const handleButtonClick = (index) => {
    setCurrentRowIndex(index); // Store the row index of the button clicked
    setDropdownVisible(true);   // Show the dropdown
    console.log(selectedRowOptions, "selectedRowOptions on button click");
  };

  const isAllSelectedForRow = (rowIndex) => {
    return selectedRowOptions[rowIndex]?.length === taxelementOptions.length;
  };

  return (
      <Fragment>
          <Breadcrumbs mainTitle="Tax Design" parent="Taxation" title="Tax Design" />
          <Container fluid={true} className='Container1'>
              <Form className="theme-form" onSubmit={handleSubmit}>
                  <Col sm="12" xl="6" className='vendorcol2a'>
                      <Card>
                          <div className='formdiv1ba'>
                              <div className='rowForm1'>
                                  <div className='formcol1'>
                                      <div className='formin1'>
                                          <Label style={{fontWeight:"500"}} className="col-form-label">Tax Cluster</Label>
                                          <Select
                                              className="forminput"
                                              ref={vendorCodeRef}
                                              value={under || ''}
                                              onMenuOpen={() => setDropdownOpen(true)}
                                              onMenuClose={() => setDropdownOpen(false)}
                                              styles={customStyles}
                                              onChange={handleSelectChange}
                                              options={selectOptions}
                                              filterOption={(option, inputValue) =>
                                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                              }
                                              formatOptionLabel={(option, { inputValue }) =>
                                                getHighlightedText(option.label, inputValue)
                                              }
                                              required
                                          />
                                          {nameError2 && (
                                            <div className="alert alert-danger mt-2">
                                                {nameError2}
                                            </div>
                                            )}
                                      </div>
                                      <FormGroup className='formin1'>
                                          <Label style={{fontWeight:"500"}} className="col-form-label">Description</Label>
                                          <Input
                                                value={description || ''} 
                                                onBlur={handleBlur}
                                                onChange={handleSelectChange}
                                                className="forminput"
                                                type="text"
                                                style={{ color: 'black', borderColor: '#afafaf' }}
                                                readOnly
                                          />                                    
                                      </FormGroup>
                                      {/* <FormGroup className='formin1'>
                                          <Label style={{fontWeight:"500"}} className="col-form-label">Tax Design Number</Label>
                                          <Input  onBlur={handleBlur} className="forminput" type="number" style={{color:"black",borderColor: "#afafaf"}} value={tax_design_number} onChange={handleTaxDesignNumberChange} onInput={(e) => {if ( /[eE]/.test(e.target.value)) {e.target.value = e.target.value.replace(/[eE ]/g, "");}}} onKeyDown={(event)=>{if (event.key === "e" || event.key === "E" || event.key === "+" || event.key === "-") {event.preventDefault();}handleEnter(event)}} required/>
                                      </FormGroup> */}
                                  </div>
                                  <br></br>
                                      <div className="subdiv">
                                          <div className="vengirdbtn">
                                        <Button className='btn2a' color='primary' type='button' style={{marginRight: 'auto', marginTop: '10px'}} onClick={handlePopup}>Tax Design Details</Button>
                                          </div>
                                      </div>
                                      <div className="vengirdbtn1">
                                                <button className='btn2' color='primary' type='submit'>{submitbtn}</button>
                                                <Button className='btn1' type='button' onClick={resetForm}>Cancel</Button>
                                      </div>
                              </div>
                          </div>
                          
                      </Card>
                  </Col>
              </Form>
          </Container>
         <Container fluid={true} className='Container1'>
         <Col sm="12" xl="6" className='vendorcol2a'>
                        <Card className=''>
                            <div style={{ display: "flex" }}>
                                <div className='headercl'>
                                <div className="h5cus">
                                   <h5 style={{paddingTop:"25px", paddingLeft:"30px"}} className="h5title">Tax Design List</h5>
                                </div>
                                </div>
                                <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
                                    <Button color="danger" className='btn1 mt-4' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
                                </div>
                            </div>
                        <CardBody>
                            <div className="table-responsive">
                                 <InputGroup className="mb-3">
                              <Input
                                  type="text"
                                  placeholder="Search"
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                  className="form-control input-no-animation inputfoc"
                                  style={{borderColor: "#afafaf" }}
                              />
                              <InputGroupText >
                                  <FaSearch />
                              </InputGroupText>
                          </InputGroup>
                          <DataTable
                                columns={columns}
                                data={paginatedData}
                                selectableRows
                                selectableRowsSelected={selectedIds}
                                onSelectedRowsChange={handleCheckboxChange}
                                clearSelectedRows={clearRows}
                                pagination
                                paginationServer
                                customStyles={customStylesTable}
                                striped={false}
                                paginationTotalRows={categories.length}
                                paginationPerPage={rowsPerPage}
                                progressPending={loading}
                                autoWidth={true}
                                paginationComponentOptions={{
                                  noRowsPerPage: true,
                                }}
                                onChangePage={handlePageChange} 
                                highlightOnHover
                          />
                            </div>
                        </CardBody>
                        </Card>
                    </Col>
          </Container>
          <div>
          {isOpen && (
                <div className="popup">
                    <div className="popup-inner" >
                  <div style={{justifyContent: 'space-between',display: 'flex'}}>
                    <div className="h5cus">
                      <h5 className="h5title">Tax Design Details</h5> 
                    </div>
                         <button className='popclsbtn' type='button' onClick={handleClose}><IoMdClose className='clsico' color='red'  /></button>
                  </div> 
                    <div className="table-container">
                        <table style={{width:"100%"}}>
                                <thead>
                                    <tr style={{height:"20px"}}>
                                      <th style={{textAlign:"center",width:"50px"}}>Index</th>
                                      <th style={{textAlign:"center"}}>Tax Element</th>
                                      <th style={{textAlign:"center"}}>Description</th>
                                      <th style={{textAlign:"center"}}>Chargeable Basis</th>
                                      <th style={{textAlign:"center"}}>Calculation Factors</th>
                                      <th style={{textAlign:"center"}}>Tax Element Details</th>
                                      {/* <th style={{textAlign:"center"}}>Tax Design Details Number</th>
                                      <th style={{textAlign:"center"}}>Load On Inventory</th>
                                      <th style={{textAlign:"center"}}>Tax Nature</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                {rows.map((row, index) => (
                                   <tr>
                                     <td>
                                      <input
                                          type="text"
                                          name="index"
                                          value={row.index}
                                          onChange={(e) => handleInputChange(index, e)}
                                          style={{height:"100%" , padding:0,width:"-38%",textAlign:"center"}}
                                          required
                                      />
                                     </td>
                                     <td>
                                     <div className="table_td_div">
                                     <input
                                         type="text"
                                         name="tax_element"
                                         value={row.tax_elementName}
                                         style={{height:"100%" , padding:0,width:"40%"}}
                                         readOnly
                                     />
                                         </div>
                                     </td>
                                     <td>
                                     <div className="table_td_div">
                                           <input
                                           type="text"
                                           value={row.description}
                                           onBlur={handleBlur}
                                           style={{height:"100%" , padding:0,width:"90%"}}
                                           // onChange={(e) => handleInputChange(index, e)}
                                           readOnly
                                       />
                                     </div>    
                                     </td>
                                     <td>
                                     <div className="table_td_div">
                                         <select
                                             type="text"
                                             name="chargeable_basis"
                                             value={row.chargeable_basis}
                                             style={{height:"100%" , padding:0,width:"90%",border:"none",color:"black"}}
                                             onChange={(e) => handleInputChange(index, e)}
                                             required
                                         >
                                           <option value={''} disabled selected>Select Category</option>
                                             {selectbasisOptions.map((selectbasisOptions)=>(
                                                 <option key={selectbasisOptions.value} value={selectbasisOptions.value}>{selectbasisOptions.label}</option>
                                             ))}
                                         </select>
                                     </div>    
                                     </td>
                                     <td>
                                     <div className="table_td_div">
                                         <input
                                             type="text"
                                             name="calculation_factors"
                                             value={row.calculation_factors_name}
                                             style={{height:"100%" , padding:0,width:"90%"}}
                                             onChange={(e) => handleInputChange(index, e)}
                                             readOnly
                                         />
                                     </div>
                                     </td>
                                     <td>
                                     <div className="table_td_div">
                                            <button className='btn2a' color='primary' type='button' onClick={() => handleButtonClick(index)}>Tax Element</button>
                                     </div>
                                     </td>
                                 </tr>
                                    ))}
                                </tbody>
                                </table>
                                </div>
                                <div style={{display:"flex"}} className="mt-3">
                                    <div style={{width:"50%"}}>
                                        {/* <Button onClick={addRow} color='primary' className='btn2a'>
                                            Add Row
                                        </Button> */}
                                    </div>
                                    <div className='popbtn' style={{width:"50%"}}>
                                        <Button className='btn2a' color='primary' type='button' onClick={handleSave}>Save</Button>{' '}
                                        {/* <Button className='btn1ab' type="button" onClick={handleCancelAbatement}>Close</Button> */}
                                    </div>
                                </div>
                    </div>
                </div>
            )}
            </div>
            {dropdownVisible && (
                      <div className="popup">
                      <div className="popup-inner" style={{ width: '500px' }}>
                      <div style={{justifyContent: 'space-between',display: 'flex'}}>  
                      <div className='h5cus'>
                      <h5 className="h5title">Tax Element Details</h5>
                      </div>   
                      <button className='popclsbtn' type='button' onClick={handleClose1}><IoMdClose className='clsico' color='red'/></button>
                      </div>
                      <div className="table-container">
                      <table style={{width:"100%"}}>                          
                        <thead style={{ position: "sticky", top: 0, backgroundColor: "#fff", zIndex: 1 }}>
                        <tr style={{height:"20px"}}>
                                <th style={{width:"70px", height:"50px"}}>
                                <div style={{justifyContent: 'center',display: 'flex'}}>
                                <input
                                  type="checkbox"
                                  id="select-all"
                                  // style={{transform: 'scale(0.7)'}}
                                  // checked={selectedOptions.length === taxelementOptions.length}  // Updated checked condition
                                  checked={isAllSelectedForRow(currentRowIndex)} // Check if all options for this row are selected
                                  onChange={(e) => handleSelectAll(e, currentRowIndex)}
                                /> {' '}
                                </div> 
                              </th>                             
                              <th style={{textAlign:"center"}}>Tax Element</th>  
                              <th style={{textAlign:"center"}}>Description</th>  
                        </tr>
                          </thead>
                          <tbody>
                            {taxelementOptions.map((option, index) => (
                              <tr key={option.id || index}>
                                <td>
                                <div className="table_td_div">
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${option.id || index}`} 
                                    value={option.label}
                                    // checked={selectedOptions.some(item => item.value === option.value)} 
                                    // onChange={(e) => handleCheckbox(e, option.label, option.value,option.description)}
                                     checked={selectedRowOptions[currentRowIndex]?.some(item => item.value === option.value) }
                                     onChange={(e) => handleCheckbox(e, option.label, option.value, option.description, currentRowIndex)}
                                     />
                                  </div>
                                </td>
                                <td style={{textAlign:"center"}}>{option.label}</td>
                                <td style={{textAlign:"center"}}>{option.description}</td> 
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        </div>
                           <div style={{marginTop: "20px"}}>
                              <div className='popbtn' style={{ position: 'absolute', right: '30px', bottom: '10px', display: 'flex', gap: '10px' }}>
                                  <Button className='btn2a' color='primary' type='button' onClick={() => handleSave1(currentRowIndex)}>Save</Button>{' '}
                                  {/* <Button className='btn1ab' type="button" onClick={handleCancelAbatement}>Close</Button> */}
                              </div>
                           </div>
                          </div>
                      </div>
                        )}
      </Fragment>
  );
};

export default Taxdesign;