import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import { Card, Container } from "reactstrap";

function Procurement(){



    return(
        
        <Container>
            <Card>
            
          
        <div className="formdivss ">
            <div className="formdivcol"> <h3> Sub Master</h3>
                    <div>
                    <Link to="/paymentbase">Payment Base</Link>
                    
                    </div>
            </div>
            <div className="formdivcol"><h3>Master</h3>
                <div>
                    <Link to="/vendorgroup">Vendor Group</Link>
                </div>    <div>
                <Link to="/vendorcategory">Vendor Category</Link>
                </div>    <div>
                    <Link to="/vendor">Vendor</Link>
                </div>

            </div>
        </div>
       
        </Card>
        

   
        </Container>
    )
   


}

export default Procurement;  


 