import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Table, CardHeader, Button,InputGroup ,InputGroupText} from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import './forms.css';
import axiosInstance from '../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import classNames from "classnames";

function VendorCategory() {
  const [name, setName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [under, setUnder] = useState(null);
  const [categories, setCategories] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectOptions, setSelectOptions] = useState([{ value: '1', label: '[] Primary' }]);
  const [nameError1 , setNameError1] = useState(null);
  const [descriptionError1, setDescriptionError1] = useState(null);
  const [nameError2 , setNameError2] = useState(null);
  const [nameError3 , setNameError3] = useState(null);
  const [descriptionError2, setDescriptionError2] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [submitbtn, setSubmintbtn] =useState('Save');
  const [opt1 , setOpt1] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const nameLen = 31;
  const desLen = 51;
  const [shouldFocusName, setShouldFocusName] = useState(false);
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);

  const rowsPerPage = 10;
  const customStylesTable = {
    header: {
      style: {
        minHeight: '40px',
        backgroundColor: '#f0f0f0',
        color: '#333',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0',
        minHeight: '40px'
      },
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000',
      },
    },
    rows: {
      style: {
        minHeight: '40px',
        borderBottom: '1px solid #ddd',
      },
      cells: {
        style: {
          textAlign: 'left',
          fontSize:"12px"
        },
      },
    },
  };
  
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '30px',
        borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
        '&:hover': {
            borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '12px', // Change the font size for the placeholder
        color: '#aaa', // Optional: Change placeholder color
      }),
    valueContainer: (provided) => ({
        ...provided,
        height: '30px', // Adjust the container height
        padding: '0 6px',
      }),
      input: (provided) => ({
        ...provided,
        margin: '0px', // Remove default margin for the input inside the select
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px', // Adjust the indicator container height
      }),
};
  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
  const highlightFirstOccurrence = (text, searchTerm) => {
    const index = text.indexOf(searchTerm); // Find the first occurrence
    if (index !== -1) {
      return [{ start: index, end: index + searchTerm.length }];
    }
    return [];
  };
  
  const VendorNameCell = ({ name, searchTerm }) => (
    <Highlighter
      highlightClassName="highlighted-text"
      searchWords={[]} // Not used since we provide custom chunks
      autoEscape={true}
      textToHighlight={name}
      findChunks={() => highlightFirstOccurrence(name.toLowerCase(), searchTerm)}
    />
  );
  const columns = [
    {
      name: (<div className='dataTableDiv'>SN</div>),
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      // width:'10%',
      width:"70px",
      cell:(row, index) =>(
        <div className='dataTableDiv' style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name: (<div className='dataTableDiv'>Category</div>),
      // width:"20%",
      selector: row => row.name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <VendorNameCell name={row.name} searchTerm={searchTerm.toLowerCase()} />
        </div>
      ),
    },
    {
      name:'',
      width:'5px',
    },
    {
      name: (<div className='dataTableDiv'>Under</div>),
      // width:"20%",
      selector: row => row.under_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          {row.under_name}
        </div>
      ),
    },
    {
      name: (<div className='dataTableDiv'>Description</div>),
      // width:"23%",
      width:"300px",
      selector: row => row.description,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          {row.description}
        </div>
      ),
      
    },
    {
      name:  (<div className='dataTableDiv'>Action</div>),
      center: true,
      // width:'15%',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];


  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCategories(categories || []);
    } else {
      const safeCategories = Array.isArray(categories) ? categories : [];
      const filteredData = safeCategories.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filteredData);
    }
  }, [searchTerm, currentPage, categories]);

  useEffect(() => {
    fetchCategories(currentPage);
    fetchSelectOptions();
  }, [currentPage,searchTerm]); 

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      let response;
      if (searchTerm) {
          response = await axiosInstance.get(`vendor/categories/search/?name=${searchTerm}`);
      } else {
          response = await axiosInstance.get(`vendor/categories/get/`);
      }
      setCategories(response.data);
      setFilteredCategories(response.data); 
    } catch (error) {
      if (error.response && error.response.status === 404 && page > 1) {
        handlePageChange(page - 1);
      } else {
        console.error("Error fetching categories:", error);
      }
    }finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCategories(page);
  };
  const fetchSelectOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('vendor/categories/get/');
      const opt = response.data.map(category => ({
        value: category.id,
        label: category.name
      }));
      setOpt1(opt);
      const filteredOptions = response.data
      .filter(category => category.id !== editId)
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setSelectOptions([{ value: '1', label: '[] Primary' }, ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name,
      description,
      under: under ? under.value : null,
    };
    setNameError1(null);
    setDescriptionError1(null);
    try {
      if (isEditing) {
        await axiosInstance.put(`vendor/categories/${editId}/`, payload);
        setIsEditing(false);
      } else {
        await axiosInstance.post('vendor/categories/create/', payload);
        setErrorMessage(null);
      }
      fetchCategories(currentPage);
      fetchSelectOptions();
      resetForm();
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data) {
        const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
        const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
        const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
        if (nameError && descriptionError) {
        } else if (nameError) {
          setNameError1(nameError)
        } else if (descriptionError) {
          setDescriptionError1(descriptionError);
        } else if (existsError) {
          setErrorMessage(existsError);
 
        }
      } else {
        setErrorMessage('An error occurred while submitting the form.');
      }
    }
  };

  const handleUpdateClick = (category) => {
    setNameError1(null);
    setNameError3(null);
    setEditId(category.id);
    setNameError2(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setErrorMessage('');
    setNameError1(null);
    setDescriptionError1(null);
    setName(category.name);
    setDescription(category.description);
    setSubmintbtn('Update');
    setUnder({ value: category.under, label: category.under_name });
    setIsEditing(true);
    fetchSelectOptions(category.id);
    setErrorMessage('');
  };

  const resetForm = () => {
    setNameError2(null);
    setNameError3(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setEditId(null);
    setErrorMessage('');
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedIds(selectedRows.selectedRows.map(row => row.id));
  };
  const handleDelete = async () => {
    try {
      // const response = await axiosInstance.get('vendor/categories/get/');
      // const categories = response.data;

      // const categoryMap = new Map(categories.map(cat => [cat.id, cat.name]));

      // const selectedNames = selectedIds.map(id => categoryMap.get(id)).filter(name => name);

      // const escapeHtml = (text) => {
      //   return text.replace(/[&<>"']/g, (match) => {
      //     const escape = {
      //       '&': '&amp;',
      //       '<': '&lt;',
      //       '>': '&gt;',
      //       '"': '&quot;',
      //       "'": '&#039;'
      //     };
      //     return escape[match];
      //   });
      // };

      // const namesText = selectedNames.map(name => `<strong>${escapeHtml(name)}</strong>`).join(', ');

      // const result = await Swal.fire({
      //   title: 'Are you sure?',
      //   html: `You are about to delete the following categories: ${namesText}. You won't be able to revert this!`,
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, delete it!'
      // });

      // if (result.isConfirmed) {
        await axiosInstance.delete('vendor/categories/delete/', {
          data: { ids: selectedIds },
        });
        fetchCategories(currentPage);
        setSelectedIds([]);
        fetchSelectOptions();
        resetForm();
      // }
    } catch (error) {
      console.error('Error:', error.response.data);
      const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
      Swal.fire('Error!',errorMessage , 'error');
    }
  };

  const handleDeleteCategory = async (id) => {
    // const result = await Swal.fire({
    //   title: 'Are you sure?',
    //   text: `You won't be able to revert this!`,
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, delete it!'
    // });

    // if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`vendor/categories/${id}/`);
        fetchCategories(currentPage);
      } catch (error) {
        console.error('Error deleting category:', error.response.data);
        const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
        Swal.fire('Error!', errorMessage, 'error');
      }

      setSelectedIds([]);
      fetchSelectOptions();
      resetForm();
    // }
  };

  const getHighlightedText = (text, highlight) => {
    const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{fontWeight:'bold' }}>{part}</span>
          ) : (
            part
          )
        )}
      </span>
    );
  };
  const validateName = (value) => {
    
   if (value.match('  ')) {
      setNameError1('Continuous spaces are not allowed ');
      return false;
    }  else if(value.length === 30){
      setNameError2(null);
    }else {
      setNameError1(null);
      return true;
    }
  };

  const handleNameChange = (e) => {
      let value = e.target.value;
      value = value.replace(/\s+/g,' ').trimStart();
      setName(value);
      validateName(value); 
  };

const handleEnter = (event) => {
  if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
    event.target.focus();  
  } 
  else if (event.keyCode === 13 && event.target.form) {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      event.target.value = event.target.value.trim();
    }
    if (dropdownOpen) {
      return; 
    } else {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form.elements, event.target);
      const nextElement = form.elements[index + 1];
      if (nextElement) {
        nextElement.focus();
      }
      event.preventDefault();
    }
  }
  const { value } = event.target;
  if (
    (event.key === ' ' && value.length === 0) || 
    (event.key === ' ' && value.endsWith(' ')) || 
    (event.key === ' ' && value.includes('  '))
  ) {
    event.preventDefault();
    return;
  }
};

const handleArrowNavigation = (event, buttonType) => {
  if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
    cancelButtonRef.current.focus();
  } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
    submitButtonRef.current.focus();
  }
};
  const handleBlur = (e) => {
    setNameError2(null);
    setDescriptionError2(null);
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
      e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
      
      if (nameError1) {
          e.target.focus();
      }else if (descriptionError1) {
        e.target.focus(); 
      }
    }
  };
  const handleDescriptionFocus = () => {
    // Perform name validation when switching to the description field
    if(isEditing){
      const otherCategories = categories.filter(category => category.id !== editId);
      if (Array.isArray(otherCategories) && otherCategories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())) {
        setShouldFocusName(true);
        setNameError3('Vendor category with this name already exists.');
        setTimeout(() => {
          const nameInput = document.getElementById('nameInput');
          if (nameInput) {
            nameInput.focus(); // Set focus on the input with id 'nameInput'
          }
      }, 0);
      }
      else {
        setNameError3(null);
      }
      
    }else{
      if(Array.isArray(categories) && categories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())){
        setShouldFocusName(true);
        setNameError3('Vendor category with this name already exists.');
        setTimeout(() => {
          const nameInput = document.getElementById('nameInput');
          if (nameInput) {
            nameInput.focus(); // Set focus on the input with id 'nameInput'
          }
        }, 0);
        return false;
      }else {
        setNameError3(null);
      }

    }
  };
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Vendor" parent="Procument" title="Vendor Category" />
      <Container fluid={true} className='Container1'>
        <div className='vendordiv1'>
        <Col sm="12" xl="6" className='vendorcol1'>
          <Card>
            <div style={{ display: "flex" }}>
              <div className='headercl'>
              <div className="h5cus">
                  <h5 style={{paddingTop:"25px",paddingLeft:"30px"}} className="h5title">Vendor Category List </h5>
                </div>
              </div>
              <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
              <Button title='Delete' color="danger" className='btn1 mt-4' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
              </div>
            </div>
            <CardBody>
              <div className="table-responsive">
                <InputGroup className="mb-3">
                  <Input
                      type="text"
                      placeholder="Search"
                      style={{color:"black",borderColor: "#afafaf",fontSize:"12px" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control input-no-animation inputfoc forminput"
                  />
                  <InputGroupText >
                      <FaSearch />
                  </InputGroupText>
                </InputGroup>
                <DataTable
                  columns={columns}
                  data={paginatedData}
                  selectableRows
                  selectableRowsSelected={selectedIds}
                  onSelectedRowsChange={handleCheckboxChange}
                  clearSelectedRows={clearRows}
                  pagination
                  paginationServer
                  reorderable={true}
                  customStyles={customStylesTable}
                  striped={false}
                  paginationTotalRows={categories.length}
                  paginationPerPage={rowsPerPage}
                  progressPending={loading}
                  autoWidth={true}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={handlePageChange}
                  highlightOnHover
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" xl="6" className='vendorcol2'>
                <Card>
                <div className="h5cus">
                  <h5 style={{paddingTop:"25px",paddingLeft:"30px"}} className="h5title">Vendor Category </h5>
                </div>
                <CardBody style={{paddingTop:"15px"}}>
                <Form className="theme-form" id='form' onSubmit={handleSubmit}>
                    <FormGroup className='custom-margin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Vendor Category</Label>
                        <Input autoComplete="off"  style={{color:"black",borderColor: "#afafaf"  }} onBlur={handleBlur} maxLength="30" id="nameInput" className={classNames("form-control", "inputfoc","forminput", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={name} onChange={handleNameChange} required/>
                    </FormGroup>
                    {nameError1 && (
                        <div className="alert alert-danger">
                            {nameError1}
                        </div>
                    )}
                    {nameError2 && (
                        <div className="alert alert-danger">
                            {nameError2}
                        </div>
                    )}
                    {nameError3 && (
                        <div className="alert alert-danger">
                            {nameError3}
                        </div>
                    )}
                    <FormGroup>
                        <Label style={{fontWeight:"600"}} htmlFor="exampleInputPassword1">Description</Label>
                        <Input onFocus={handleDescriptionFocus} maxLength="50" onBlur={handleBlur} onKeyDown={handleEnter} id='desInput' style={{color:"black",borderColor: "#afafaf" }}  onChange={(e)=>setDescription(e.target.value)} className={classNames("form-control", "inputfoc1", {"is-invalid": descriptionError1,})} type="textarea" placeholder="" value={description} />
                    </FormGroup>
                    {descriptionError1 && (
                        <div className="alert alert-danger">
                            {descriptionError1}
                        </div>
                    )}
                    {descriptionError2 && (
                        <div className="alert alert-danger">
                            {descriptionError2}
                        </div>
                    )}
                   <div className="mb-2">
                        <Label style={{fontWeight:"600"}} className="label1">Under</Label>
                        <Select
                            value={under}
                            onFocus={handleDescriptionFocus}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={customStyles}
                            onChange={(option) => setUnder(option)}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={selectOptions}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            formatOptionLabel={(option, { inputValue }) => 
                              getHighlightedText(option.label, inputValue)
                            }
                            required
                        />
                    </div>
                    {errorMessage && (
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    )}
                    <div className="mt-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end"}}>
                        <button className='btn2' color='primary' ref={submitButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'save')} type='submit' disabled={nameError1 || nameError2 || nameError3 || descriptionError1 || descriptionError2 || (name === '') || (under === null)}>
                            {submitbtn}
                        </button>
                        <button className='btn1' ref={cancelButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'cancel')} onClick={resetForm}>
                            Cancel
                        </button>
                        
                    </div>
                </Form>
                </CardBody>
                </Card>
            </Col>
        </div>
      </Container>
    </Fragment>
  );
}

export default VendorCategory;
